@import 'src/app/static/styles/main.scss';


.homepage-new {
    .homepage-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .header {
            text-align: left;
            font-size: 3rem;
            font-weight: bold;
            color: black;

            &.blue {
                color: rgb(65, 118, 187);
            }
        }

        .carousel-section-outer {
            display: flex;
            flex-direction: column;
            background-color: rgb(243, 243, 243);
            width: 100%;

            .carousel-section {
                margin-top: 50px;
                margin-bottom: 80px;
                display: flex;
                flex-direction: column;

                .carousel-wrapper {
                    margin-top: 24px;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 32px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .formwrapper-bottom {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 100px;

            .category-3-wrapper {
                display: flex;
                flex-direction: column;
                padding-top: 10px;
                padding-bottom: 0px;
            }
        }

        .multicolor-headers {
            display: flex;
            flex-direction: row;
            text-align: center;
            gap: 12px;
            padding-bottom: 55px;

            &.vendor-list {
                padding-bottom: 20px;
            }
        }

        .vendors-list {
            margin: 100px auto;
            margin-bottom: 160px;
            display: flex;
            justify-content: flex-start;
            column-gap: 60px;
            width: 100%;
            overflow: scroll;
            padding-right: 75%; // to be able to scroll all the way right on narrow browsers

            .vendor-box {
                display: flex;
                flex-direction: column;
                padding: 16px;
                height: 260px;
                width: 240px;
                border-radius: 16px;
                background-color: rgba(0,0,0,0);
                text-align: center;
                transition: 500ms;

                &:hover,
                &:focus {
                    background-color: rgba(0,0,0,.1);
                }

                .vendor-img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: calc(240px - (16px * 2));
                    height: 190px;
                    border-radius: 12px;
                    background-color: rgb(243, 243, 243);
                    overflow: hidden;

                    img.vendor-img {
                        width: 80%;
                    }
                }

                .vendor-name {
                    margin-top: 2rem;
                    font-size: 1.8rem;
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                }

            }



        }

    }
}