@import 'src/app/static/styles/main.scss';

.navbar-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: $header-height;
  width: 100%;
  height: $navbar-height;
  box-shadow: 0px 5px 5px rgba(0,0,0,.1);
  
  .nav-background {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $navbar-background-gradient-opaque;
    opacity: .8;
    transition-duration: 100ms;  
    transition-property: opacity;

    &.opaque {
      opacity: 1;
    }
  }

  .navbar-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 11;
  }
}

.nav-image {
  object-fit: cover;
  margin-top: 3px;
  width: 100%;
  height: 75px;
}


/** 
 * Responsive styling
 */
@include screen-below-mobile-820 {
  .navbar-wrapper {
    top: $header-height * .8;
    height: $navbar-height * .8;
  }
}