@import 'src/app/static/styles/main.scss';



.paging-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  .left-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    column-gap: 20px;

    .text {
      font-weight: 100;
      width: 250px;
    }
  }

  .paging {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;

    .wrap {
      background-color: white;
      color: colors(_purple);
      border: 1px solid colors(_purple);
      font-weight: bold;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: colors(_purple);
        color: white;
      }

      &.radius-left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.radius-right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.middle-purple {
        padding: 10px 13px;

        &:active {
          background-color: colors(_purple);
          color: white;
        }
      
        &:focus {
          background-color: colors(_purple);
          color: white;
        }
      
        &.active {
          background-color: colors(_purple);
          color: white;
        }
      }
    }
  }
}