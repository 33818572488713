@import 'src/app/static/styles/main.scss';

$arrow-size: 20px;
$arrow-top: -8px;

.acc-dropdown {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-weight: 600;

  .nav-bar {
    position: relative;
    font-size: 1.8rem;

      &:hover .dropdown-content {
        display: block;
      }
    
    .dropbtn {
      display: inline-block;
      color: white;
      text-align: center;
      padding: 12px;
      text-decoration: none;
    }

    .dropdown-content {
      position: absolute;
      right: 0;
      margin-top: 14px;
      padding: 15px 10px 7px 10px;
      width: fit-content;
      min-width: 170px;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0px 0px 12px 3px rgba(10, 10, 10, 0.18);
      display: none;

      &.open-left {
        right: initial;
        left: 0;
      }

      .filler {
        width: 170px;
        margin-top: -40px;
        padding-bottom: 40px;
      }

      .small-arrow {
        position: absolute;
        top: $arrow-top;
        right: 30px;
        height: $arrow-size;
        width: $arrow-size;
        transform: scaleX(1.5) rotate(45deg);
        background-color: #fff;
      }

      &.open-left .small-arrow {
        left: 30px;
      }
  
      a {
        font-size: 1.5rem;
        height: 30px;
        padding: 8px 8px 0px 8px;
        margin: 5px 5px 8px 5px;
        font-weight: bold;
        color: #070d27a3;
        text-decoration: none;
        display: block;
        text-align: left;
    
        &:hover,
        &:focus {
          background-color: rgba(128, 128, 128, 0.12);
        }

        &.contact-link:hover,
        &.contact-link:focus {
          text-decoration: underline;
        }
      }
    }
  }
}


/** 
 * Responsive styling
 */
@include screen-below-mobile-820 {
  .acc-dropdown .nav-bar {
    font-size: 1.4rem;
  }
}

