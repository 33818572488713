@import 'src/app/static/styles/main.scss';


.product-detail-page-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

.header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: black;
    
    &.blue {
        color: rgb(65, 118, 187);
        padding-bottom: 30px;
    }
    
    &.small {
        font-size: 2rem;

        &.blue {
          color: rgb(65, 118, 187);
        }
    }
}

.formwrapper-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 60px;

    .category-1-wrapper {
        display: flex;
    }
    
    .category-2-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        background-color: white;
    }
}

.formwrapper-mid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 60px;
    width: 100%;
    background-color: rgb(243, 243, 243);

    .category-3-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 40px;
        padding-bottom: 80px;
        width: 100%;
    }
}

.formwrapper-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  
.category-4-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: 80px;
  background-color: white;
}
}

.product-display-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 45px;
}

.multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-bottom: 50px;
}

.pdf-web-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 120px;
  padding-bottom: 50px;


  .icons {
    width: 25px;
    margin: 0px 30px -5px 0px;
  
    &.web-icon {
        
    }
  
    &.pdf-icon {
      
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  li {
    font-weight: bold;
    padding: 25px 15px 0px 0px;
    width: 100%;
  }
}


.formwrapper-bottomform {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10%;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 12px;
  }

  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: black;

    &.blue {
        color: rgb(65, 118, 187);
    }
  }

  p {
    padding-bottom: 60px;
  }

  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10%;

  .physician-referral-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
    padding-bottom: 180px;
    gap: 20px;

    .category-1-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      gap: 15px
    }

    .category-2-wrapper {
      display: flex;
      flex-direction: row;
      gap: 15px
    }

    .category-3-wrapper {
      gap: 15px;
    }

    .category-4-wrapper {
      display: grid;
      grid-template-columns: 2.2fr 1fr 1fr;
      gap: 15px
    }
  }

  .privacy-container {
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: flex-end;
    margin-bottom: 285px;
    gap: 30px;
    

    .privacy-image {
      width: 60px;
    }

    .header-small {
      font-size: 2rem;
      font-weight: bold;
    }

    .privacy-text {
      line-height: 23px;
    }
  }
}
}
}