@import 'src/app/static/styles/main.scss';

$slide-btn-height: 10px;

.carousel-camera-roll {
  position: relative;
  height: calc(400px + ($slide-btn-height * 2));
  width: 500px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .slideshow-wrapper {
    position: relative;
    height: calc(100% - ($slide-btn-height * 2));
    display: flex;

    .slide {
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 100%;
      height: 100%;

      .slide-img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }
  }

  .slide-btns-container {
    display: flex;
    justify-content: center;

    .slide-btn {
      padding: ($slide-btn-height / 2);
      
      .slide-btn-inner {
        width: $slide-btn-height;
        height: $slide-btn-height;
        background-color: rgba(141, 140, 140, 0.516);
        outline: none;
        border-radius: 50%;
        border: 0px solid rgba(141, 140, 140, 0.516);
        cursor: pointer;
        transition-duration: 200ms;
      }

      &:focus,
      &:hover,
      &:active {
        .slide-btn-inner {
          background-color: colors(_purple);
          border: 1px solid colors(_purple);
        }
      }

      &.active {
        .slide-btn-inner {
          background-color: rgba(0, 8, 48, .5);
        }
      }
    }
  }
}
