@import 'src/app/static/styles/main.scss';

.breadcrumb-trail-container {
    padding: 8px 40px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.admin-pages {
        position: fixed;
        top: 50px;
        background-color: #def;
    }

    .node {
        margin: 0 10px;
        font-weight: 600;
        line-height: 1.5;
        border-radius: 6px;
        display: flex;
        transition-duration: 200ms;

        &.parent {
            color: #99a;

            &:hover {
              padding: 0 10px;
                background-color: #bdf;
            }
        }

        &.target {
            padding: 0 10px;
            color: colors(_blue_gray);
            background-color: #9df;
        }
    }

    .descendant-arrow {
        line-height: 1.5;
        color: colors(_gray_med);
    }

    select#child-pages {
        margin-left: 12px;
        padding-left: 8px;
        padding-right: 40px;
        height: auto;
        color: colors(_gray_med);
        background-color: #eeeef2;
        line-height: 1.5;
        border: 1px solid colors(_gray_med);
    }

    .children-box {
        margin-left: 12px;
        position: relative;
        height: 27px;
        min-width: 100px;
        overflow: visible;

        &.siblings {
            margin-left: 0;
            min-width: unset;
        }

        .children-box-text {
            height: 27px;
            width: fit-content;
            min-width: 60px;
            padding: 2px 16px;
            border: 2px solid #ccc;
            border-radius: 6px;
            font-weight: 600;
            color: colors(_blue_gray);
        }

        &.siblings .children-box-text {
            padding: unset;
            height: unset;
            min-width: unset;
            border: unset;
        }
        
        .children-box-inner {
            position: relative;
            margin: 0;  // this gets changed on hover
            padding: 0;  // this gets changed on hover
            width: fit-content;
            max-height: 0; // this gets changed on hover
            overflow: hidden;
            transition-property: margin, padding, max-height;
            transition-duration: 1ms, 1ms, 1ms, 400ms;

            .children-dropdown {
                margin-top: 24px;
                padding: 0 8px;
                border: 2px solid rgba(0, 4, 16, .1);
                box-shadow: 4px 8px 20px 10px rgba(0, 0, 0, 0);
                border-radius: 8px;
                background-color: #def;
                outline: 4px solid #fff;
                color: #778;
                font-size: 16px;
                font-weight: 600;
                overflow: hidden;
                transition-duration: 200ms;
                
                
                .children-row {
                    padding: 8px 16px;
                    border-top: 1px solid #aab;
                    white-space: nowrap;
                    cursor: pointer;

                    &:first-child {
                        border-top: none;
                        padding-top: 16px;
                    }
                    &:last-child {
                        padding-bottom: 16px;
                    }

                    &:hover {
                        color: colors(_blue_gray);
                    }
                }
            }
        }

        &:hover .children-box-inner {
            // These three vals should be the same number.
            padding: 25px;
            margin: -25px -25px -25px -25px;
            // Other effects.
            max-height: 500px;
            // Different transition times when closing
            transition-property: margin, padding, max-height;
            transition-duration: 400ms, 400ms, 400ms;

            .children-dropdown {
                box-shadow: 4px 8px 20px 2px rgba(0, 0, 0, .1);
            }
        }
    }

}