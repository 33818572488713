@import 'src/app/static/styles/main.scss';


.tag-reassign-modal.tag-reassign-modal {
    .modal-main {
        position: relative;
        width: 50%;
        min-width: 500px; 
        max-width: 800px;

        .title {
            font-size: 2.4rem;
            color: rgba(0, 4, 12, .75);
        }

        .sub-title {
            font-size: 1.8rem;
            font-style: italic;
            color: rgba(0, 4, 12, .5);
            text-align: center;
        }

        .regular-text {
            margin: 60px 0 0;
            font-size: 1.8rem;
            line-height: 1.4;
            color: rgba(0, 4, 12, .7);
            text-align: center;
        }

        .radio-row {
            margin-top: 60px;
            display: flex;
            justify-content: center;
            column-gap: 40px;

            input[type="radio"] {
                display: none;

                // For selected fields
                &:checked + label {
                    outline: 2px solid #00f;
                }
            }
            label {
                margin-right: 10px;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 4px;
                cursor: pointer;
            }
            input[type="radio"]:checked + label {
                background-color: #ccc;
            }
        }

        .reassign-to-input {
            margin: 20px 0 20px 50%;
            transform: translateX(-50%);
            padding: 12px 20px;
            width: 80%;
            max-width: 400px;
            background-color: rgba(0, 0, 4, .07);
            border-radius: 6px;
            text-align: center;
            color: rgba(0, 4, 12, .8);
            font-size: 1.6rem;
            white-space: pre-wrap;
        }

        .buttons-row {
            display: flex;
            justify-content: space-around;
            width: 100%;

            .block-button {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        .error-message {
            margin-top: 40px;
            font-size: 1.6rem;
            font-weight: bold;
            color: red;
            white-space: pre-wrap;
            text-align: left;
        }

        .success-message {
            margin-top: 40px;
            font-size: 1.6rem;
            font-weight: bold;
            color: green;
            white-space: pre-wrap;
            text-align: center;
        }

        .email-input {
            margin-top: 40px;
            padding: 12px 20px;
            width: 100%;
            background-color: rgba(0, 0, 4, .07);
            color: rgba(0, 4, 12, .8);
            font-size: 1.6rem;
            border-radius: 6px;
            white-space: pre-wrap;
        }
    }
}
