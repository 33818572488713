@import './app/static/styles/main.scss';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $page-background-color;
  
  // overflow-x: hidden;
  
  #root {
    // Background color for the body section of the page. This is covered 
    // up by the background set by the `body` selector in _global-styling.scss, which affects
    // only the area below the footer.
    background-color: #fff;
  }
}
