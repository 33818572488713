@import 'src/app/static/styles/main.scss';


.select-component-wrapper {
    display: flex;
    overflow: hidden;

    // This class is defined in CreditApplicationStep1.tsx
    // - the width here, added to the width of `.city` class in input-field.scss, should add up to ~100%.
    &.credit-app-state {
        margin-left: 10px;
        width: calc(40% - 10px);
    }
    // For CreditApplicationStep3.tsx
    &.credit-app-credit-type {
        width: 100%;
    }

    .label {
        text-transform: uppercase;
        padding-right: 10px;
        font-size: font-sizes(inputSelect);
        font-weight: bold;
        display: flex;
        align-self: center;
    }

    .select-component-wrapper-inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        select {
            padding-left: 20px;
            width: 100%;
            // replace default dropdown arrow
            background: url("../../../../static/icons/icon-drop-arrow.svg") no-repeat right colors(light-grey);
            background-size: 1.5rem 1.5rem;
            background-position: right 10px top 50%;
            height: font-sizes(input) * 2.5;
            border: none;
            border-radius: 5px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            color: colors(primary-blue);
            font-size: font-sizes(input);

            &:focus {
                outline: none;
            }

            &:disabled {
                color: rgba(0,0,8,.6);
                font-weight: 400;
                background: colors(light-grey);
            }
        }


        /*
        * ===================  Alt styles
        */
        &.white-bg {
            select {
                background: #fff;
            }
        }

        &.outlined {
            select {
                background-color: white;
                color: black;
                border-radius: 4px;
                border: 2px solid black;
                padding: 8px 16px;
            }
            
            &.faded {
                    select {
                        border: 2px solid colors(light-grey);
                        padding: 8px 10px;
                    }
            }
        }


        &.transparent-bg {
            select {
                background: transparent;
            }
        }

        &.bold-text {
            select {
                font-weight: font-weights(bold);
            }
        }

        &.light-border {
            border: $light-border;
        }

        &.color-base {
            color: colors(primary-blue);
        }

        &.full-height {
            height: 100%;

            select {
                height: 100%;
            }
        }

        @for $i from 1 through 100 {
            &.pad-#{$i} select {
                padding: #{$i}px;
            }
        }

        @for $i from 1 through 10 {
            &.border-radius-#{$i} {
                border-radius: #{$i}px;
            }
        }

        .success-message {
            margin-top: 4px;
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: bold;
            color: #394;
            white-space: pre-wrap;
        }

        .error-message {
            margin-top: 4px;
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: bold;
            color: red;
            white-space: pre-wrap;
        }
    }



    // Alternate display, used on registration forms (and probably anywhere that needs to show
    // error messages or success messages underneath the input).
    &.alt {
        overflow: visible;
        font-weight: 600;

        .error-messsage,
        .success-message {
            margin: 2px 0 -18px 0;
        }
    }
}