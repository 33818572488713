@import 'src/app/static/styles/main.scss';


.product-upload-greyback {
    position: relative;
    padding: 50px 75px 50px 55px;
    width: 230px;
    height: 230px;
    border-radius: 10px;
    border: 1px solid #dde;
    background-color: rgba(93, 93, 93, 0.092);
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0.7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .vendor-product-primary-image {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .delete-product-button {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 0 0 0 2px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #889;
      background-color: rgba(255,255,255);
      color: #445;
      font-size: 20px;
      // text-align: center;
      text-transform: capitalize;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      overflow: hidden;
      opacity: .4; // !!
      z-index: 3;
      transition-duration: 200ms;
      outline: 1px solid #def;
    
      &:hover,
      *:focus {
        opacity: .6;
        box-shadow: 0 0 8px rbga(0, 0, 0, .5);
      }
    }

    .vendor-add-product-icon {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 40px;
      width: 40px;
    }

    .invisible-add-product-button {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    .vendor-adding-product-spinner {
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100px;
      width: 100px;
    }
}