@import 'src/app/static/styles/main.scss';



.physician-profile {
    .section {
        h2 {
            font-size: font-sizes(h2);
            font-weight: font-weights(bold);
        }

        &.products {
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0 10%;


                .product-list-item {
                    margin-top: 15%;

                    &:nth-child(1),
                    &:nth-child(2) {
                        margin-top: 0;
                    }

                    &>a {
                        display: block;
                        color: colors(primary-blue);

                        .product-listing {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            gap: 5%;
                            
                            .img-wrapper {
                                aspect-ratio: 1/1;
                            }

                            .product-summary {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                h4 {
                                    font-size: font-sizes(h4);
                                    font-weight: font-weights(bold);
                                    text-transform: uppercase;
                                }

                                p {
                                    margin-top: 20px;
                                }

                                .brand,
                                .vendor {
                                    font-weight: font-weights(semi-bold);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.events {
            margin: 300px 0;
            
            .events-gallery {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                .event {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba($color: #000, $alpha: 0.1);

                    .bg {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: -1;
                    }

                    &.main-event {
                        aspect-ratio: 1/1;
                    }

                    p {
                        text-transform: uppercase;
                        text-align: center;
                        color: #fff;
                        font-weight: font-weights(semi-bold);
                    }
                }

                .sub-events {
                    display: grid;
                    grid-template-rows: 1fr 1fr;
                    gap: 10px;
                }
            }
        }
    }
}