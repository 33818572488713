@import 'src/app/static/styles/main.scss';

$col-gap: 40px;
$col-gap-2: 0px;

footer.desktop-footer {
  width: 100%;
  background: colors(_blue);

  &.is-admin {
    display: none;
  }

  // ========================================  general

  // Don't let link text wrap on narrow widths.
  .column-header,
  .category-link,
  .footer-link {
    white-space: nowrap;
  }


  // These wrap groups of 2 `.column` elements, so that when columns wrap in narrow screens
  // they wrap 2 at a time. Columns can also wrap within wrap blocks.
  .wrap-block {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    row-gap: 40px;
    width: max-content;

    .column {
      margin-bottom: 32px;
      width: fit-content;
      flex-grow: 1;

      .column-header {
        margin-bottom: 36px;
        text-transform: uppercase;
        font-size: font-sizes(h6);
        font-weight: 600;
        color: #fff;
      }
    }
  }

  // Modifications to `wrap-block` for the "categories" section.
  .categories .wrap-block {
    justify-content: space-evenly;
    column-gap: $col-gap;

    .column {
      max-width: calc((100% - ($page-side-padding * 2) ) / 2); // aligns lower items with above items when `.wrap-block` wraps.
      min-width: 120px;
    }
  }

  // Modifications to `wrap-block` for the "footer-nav" section.
  .footer-nav .wrap-block {
    justify-content: space-evenly;
    column-gap: $col-gap-2;

    .column {
      max-width: calc((100% - ($page-side-padding * 2) - $col-gap-2) / 2); // aligns lower items with above items when `.wrap-block` wraps.
      min-width: 200px;
    }
  }


  // ========================================  upper section
   .categories {
    padding-top: 28px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: $col-gap;

    .category-link {
      display: block;
      color: #fff;
      font-size: font-sizes(footer_small);
      margin-top: 26px;
    }
  }

  // ========================================  lower section
  .footer-nav-wrapper {
    padding-top: 24px;
    background: colors(dark-blue);

    .footer-nav {
      padding: 6px 20px 20px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: $col-gap-2;

      .footer-link {
        color: #fff;
        display: block;
        font-size: font-sizes(footer_small);
        margin-top: 28px;
      }

      .column {
        padding-right: 20px;
        width: max-content;

        &.contact {
          padding-right: 40px; // Keep email-address text from touching the column to its right.

          .contact-link {
            &:hover {
              text-decoration: underline;
              opacity: .7;
              z-index: 100;
            }
          }
        }

        &.subscribe {
          padding-right: 80px; // Keep text from getting truncated at right of screen (around ~980px width).

          .column-header {
            text-transform: none;
          }

          .subscribe-contents {
            width: min-content;

            .column-header {
              text-transform: none;
            }

            .subscribe-text {
              margin: 15px 0 30px;
              color: #fff;
              font-size: font-sizes(footer_small);
              line-height: 1.2;
            }
          }

          // Subscribe input & button
          .subscribe-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
        
            input {
                padding: 8px 12px;
                width: 160px;
                border: none;
                background: colors(blue-grey);
                color: #fff;
        
                width: calc(100% - 48px);
                text-align: left;
        
                &::placeholder {
                    color: rgba(255,255,255,.5);
                    font-size: 88%;
                }
        
                &:focus {
                    outline: none;
                }
            }
        
            button {
                text-transform: uppercase;
                border: none;
                background-color: colors(_purple);
                padding: 11px 12px;
                color: #fff;
                font-weight: 600;
                
                width: 48px;
                text-align: center;
            }
        
            button:focus,
            input:focus {
                outline: none;
            }
        }
        }
      }
    }
  }

  // ========================================  copyright section
  .copyright-outer {
    width: 100%;
    border-top: 1px solid #fff;
    padding-top: 20px;
    padding-bottom: 20px;

    .copyright {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      align-items: flex-end;

      img.logo-for-copyright {
        display: inline-block;
        margin-right: 40px;
        width: 200px;
      }

      .copyright-text {
        color: #c1c1c9;
        font-size: font-sizes(footer_small);
      }
    }
  }
}
