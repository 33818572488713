$circle-width: 36px;

.div-contain-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;

  .progress-line {
    flex-grow: 1;
    height: 5px;
    background-color: rgb(89, 190, 204);

    &.disabled {
      background: #f0f0f0;
    }
  }

  .progress-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $circle-width;
    height: $circle-width;
    background-color: rgb(89, 190, 204);
    border-radius: 50%;
    cursor: pointer;

    &.checkmark {
      width: 28px;
      height: 28px;
      clip-path: polygon(39% 62%, 82% 27%, 90% 36%, 39% 76%, 10% 53%, 19% 44%);
      background-color: #fff;

      &.blue-check {
        background-color: rgb(89, 190, 204);
      }
    }

    &.white-circle {
      background-color: #fff;
      border: 3px solid rgb(89, 190, 204);
    }

    &.disabled {
      background-color: #f0f0f0;
    }
  }
}
