@import 'src/app/static/styles/main.scss';


.vendor-profile-page-layout {  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .error-message {
        margin: 100px 0 60px; // big top margin so not hidden by navbar.
        font-size: 1.6rem;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
    }

    .header {
        text-align: left;
        font-size: 3rem;
        font-weight: bold;
        color: black;

        &.blue {
            color: rgb(65, 118, 187);
        }
        
        &.small {
            font-size: 2.8rem;
        }

        &.small.blue {
            color: rgb(65, 118, 187);
        }
    }

    .formwrapper-products {
        display: flex;
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 80px;
        width: 100%;
    }

    .formwrapper-mid {
        display: flex;
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;
        background-color: rgb(243, 243, 243);
    }

    .formwrapper-bottom {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 90px;
        background-color: rgb(243, 243, 243);
    }

    .category-1-wrapper {
        display: flex;
        // justify-content: space-between;
    }

    .products-text {
        padding-top: 15px;
    }

    




    .multicolor-headers {
        display: flex;
        flex-direction: row;
        gap: 7px;
    }

    .category-2-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        background-color: white;
    }

    .category-3-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 80px;
        padding-bottom: 80px;
        width: 100%;
    }

    .category-4-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 80px;
        padding-bottom: 80px;
        background-color: white;
    }

    .category-5-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 80px;
        padding-bottom: 80px;
        width: 100%;
    }

    .cards {
        $column-gap: 100px;

        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: $column-gap;
        row-gap: 60px;
        width: 100%;

        .card {
            border-radius: 10px;
            width: calc(50% - ($column-gap /2));
            padding: 60px;
            background-color: white;
            box-shadow: 10px 10px 20px 3px rgba(117, 117, 117, 0.129);

            .dot {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin: 4px 23px 0px 0px;
                border-radius: 50%;
                background-color: colors(_purple);
            }

            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
          
            li {
                display: flex;
                flex-direction: row;
                margin-top: 24px;
                padding-right: 16px;
                height: 100%;
                font-weight: 600;
                font-size: 1em;
                line-height: 120%;
                // padding: 25px 15px 0px 0px;
                width: 100%;

                .li-text {
                    width: calc(100% - 10px);

                    a {
                        color: colors(_blue);
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
            }
        }

        .certifications {
            height: 300px;
            width: 650px;
            border-radius: 10px;
            background-color: white;
        }
    }

    .pdf-web-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 120px;
        padding-bottom: 150px;

        .icons {
            width: 25px;
            margin: 0px 30px -5px 0px;
        }
        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
        li {
            font-weight: bold;
            padding: 25px 15px 0px 0px;
            width: 100%;
        }
    }

}

@include screen-lg-and-below { 
    .cards .card.card {
        width: 90%;
    }
}