@import 'src/app/static/styles/main.scss';

.add-product-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  .bg-shade {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    max-width: 95%;
    max-height: 95%;
    min-width: 460px;
    min-height: 600px;
    border-radius: 5px;
    overflow: scroll;

    .modal-main-inner {
      position: relative;
      padding: 50px;
      height: 100%;
      width: 100%;

      .close-button {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 30px;
        height: 30px;
        color: #000;
        opacity: .2;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
      
        &:hover,
        *:focus {
          opacity: .3;
        }
      }

      .title {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 2.8rem;
        font-weight: 600;
        color: colors(dark-blue);
      }

      .subtitle {
        margin-bottom: 2rem;
        text-align: center;
        font-size: 2rem;
        font-style: italic;
      }

      .image-upload-container-outer {
        margin: 50px 0 80px 0;
        width: 100%;
        overflow-x: scroll;

        .image-upload-container {
          position: relative;
          display: flex;
          justify-content: center;
          gap: 40px;
          overflow: scroll;
          width: fit-content;
        }
      }

      .header {
        margin: 50px 0;
        font-size: 3rem;
        text-align: left;
        font-weight: bold;
        color: rgb(65, 118, 187);
      }

      .subheader {
        margin-top: 16px;
        font-size: 2rem;
        color: rgba(0, 0, 0, .7);
        font-weight: 400;
        font-style: italic;
      }

      .input-row {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 40px;

        .input-column-title {
          font-size: 1.4rem;
          font-weight: 600;
          color: rgba(0,0,16, .8);
        }

        .row-number {
          margin-top: 18px;
          font-size: 2rem;
          font-weight: bold;
          color: rgba(0,0,16, .8);
        }

        .delete-row-button,
        .add-row-button {
          margin-top: 6px;
          opacity: .35;
          height: 40px;
          width: 40px;
          font-size: 24px;
          color: rgba(0, 0, 8, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          transition-duration: 200ms;

          &:hover {
            opacity: .55;
          }

          &.noclick {
            cursor: default;
            opacity: 0;

            &:hover {
              opacity: 0;
            }
          }
        }

        .add-row-button {
          padding: 20px 100px;
          height: 80px;
          width: 240px;
          color: rgba(0, 0, 16, 1)
        }
      }

      .success-message {
        margin: 40px;
        font-size: 2.4rem;
        font-weight: bold;
        color: green;
        white-space: pre-wrap;
        text-align: center;
      }

      .error-message {
        margin: 40px;
        font-size: 2.4rem;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
        text-align: center;
        white-space: pre-wrap;
      }

      .buttons-container {
        margin-top: 60px;
        display: flex;
        justify-content: space-around;
      }

      .foreground-overlay {
        z-index: -1;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,16,.58);
        transition-duration: 400ms;

        &.show {
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }

  .spinner {
    z-index: 3;
    opacity: .6; // just to make colors a bit softer.
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150px;
    width: 150px;

    &.skus {
      position: relative;
      top: unset;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}


/** 
 * Responsive styling
 */
@include screen-below-mobile-820 {
  .add-product-modal .input-row.sku-row.sku-row {
    margin-top: 10px;
    gap: 10px;
  }
}
