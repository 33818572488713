@import 'src/app/static/styles/main.scss';



.product-inventory-layout {
  
  .header {
    padding-top: 200px;
    padding-bottom: 60px;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
}
}