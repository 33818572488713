@import 'src/app/static/styles/main.scss';

.container-ds-idle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 400px;
  height: 330px;
  padding-bottom: 140px;  
}
/* ----- end of container stylings ----- */

.content-ds-idle {
  position: relative;
}

/* ----- carousel content stylings ----- */
/* places the carousel content on center of the carousel */
.carousel-content-ds-idle {
  position: absolute;
  /*to center the content horizontally and vertically*/
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

/*----- end of carousel content stylings ----- */

/* ----- slideshow stylings ----- */
.doctorslideshow-idle {
  background-color: rgb(243, 243, 243);
  width: 700px;
  height: 300px;
  overflow-y: visible; /* to hide slides in x-direction */
  position: relative;
}
/* wrapper which wraps all the slideshow images stylings */
.doctorslideshow-wrapper-idle {
  display: flex;
  width: 50px;
  height: 100%;
  position: relative;
}

/* define width and height for images*/
.doctorcard-idle {
  margin-bottom: 76px;
  background-color: white;
  border-radius: 7px;
  width: 600px;
  height: 330px;
  align-items: center;
  box-shadow: 0px 15px 15px 3px rgba(95, 93, 93, 0.13);
}

.doctorcard-idle-background {
  background-color: white;
  border-radius: 7px;
  width: 670px;
  height: 300px;
  align-items: center;
  box-shadow: 0px 15px 15px 3px rgba(95, 93, 93, 0.13);
}

.doctorslide-idle-background {
  padding-left: 15px;
  padding-top: 15px;
  width: 100%;
  height: 100%;
}

.slide-img-ds-idle {
  margin: 38px 0px 0px 35px;
  padding: 0px 15px 35px 0px;
  width: 40%;
  height: 88%;
  object-fit: contain;
}
