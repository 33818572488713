@import 'src/app/static/styles/main.scss';



.new-physician-dashboard-layout {

  .greyback-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .greyback-content {
      display: flex;
      flex-direction: row;
    //  justify-content: space-around;
      align-items: center;
      width: 100%;
      gap: 30px;

        img {
          width: 110px;
          margin-left: 70px;
        }

      .greyback-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        gap: 30px;
        width: 60%;
        margin-left: 50px;
        font-weight: 400;

        text {
          font-weight: bold;
          font-size: 1.9rem;
        }
      }
    }
  }

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-bottom: 50px;
  }
  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: black;
    margin-top: 80px;

    &.blue {
        color: rgb(65, 118, 187);
    }
  }
  

  .events-wrapper {
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    .events-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .event {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000, $alpha: 0.1);

        .bg {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
        }

        &.main-event {
          aspect-ratio: 1/1;
        }

        p {
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-weight: font-weights(semi-bold);
        }
      }

      .sub-events {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 10px;
      }
    }
  }
}