@import 'src/app/static/styles/main.scss';



.contactpage-layout {



.formwrapper-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  margin-top: 90px;
  padding-bottom: 180px;
  gap: 35px;

    .category-1-wrapper {
      display: flex;
      flex-direction: row;
      gap: 25px
    }

    .category-2-wrapper {
      gap: 35px;
    }
  }
}