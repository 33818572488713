@import 'src/app/static/styles/main.scss';

$row-height: 36px;

.search-dropdown {
  position: relative;
  overflow: visible;
  user-select: none;
  cursor: pointer;

  &:hover .active-category {
    background-color: rgba(0,4,12,.07);
    opacity: .9;
  }

  &:active .active-category {
    background-color: rgba(0,4,12,.04);
    opacity: .8;
  }

  .active-category {
    margin-left: -$search-container-padding-horizontal;
    padding-left: $search-container-padding-horizontal;
    padding-right: $search-container-padding-horizontal + ($dropdown-icon-size * 1.5);
    width: fit-content;
    height: $search-container-height;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid rgba(0,0,8,.01);
    background-color: rgba(0,0,8,.04);
    color: colors(primary-blue);
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 11;

    svg {
      position: absolute;
      right: 15px;
      height: $dropdown-icon-size;
      width: $dropdown-icon-size;
    }
  }

  .dropdown-menu-container {
    position: absolute;
    top: 0;
    left: -$search-container-padding-horizontal;
    height: $search-container-height;
    width: calc(100% + $search-container-padding-horizontal);
    overflow: hidden;
    z-index: 2;

    &.show {
      height: auto;
      overflow: visible;
    }
    
    .dropdown-rows {
      margin-top: $search-container-height;
      min-width: 150px;
      width: fit-content;

      .dropdown-spacer {
        width: 100%;
        height: 8px;
      }

      .dropdown-rows-inner {
        background-color: #fff;
        box-shadow: 0px 0px 12px 3px rgb(10 10 10 / 18%);
        border-radius: 4px;

        .dropdown-row {
          position: relative;
          padding: 12px 12px;
          height: $row-height;
          width: max-content;
          min-width: 100%;
          font-size: 1.5rem;
          text-align: center;
          color: rgba(7, 13, 39, 0.5);
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.current {
            color: colors(primary-blue);
            font-size: 1.55rem;
          }

          &:hover {
            background-color: #f1f1f1;
          }

          &.title {
            border-bottom: 2px solid rgba(0,0,0,.1);
            cursor: default;

            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;

  .hidden {
    display: none;
  }
}


/** 
 * Responsive styling
 */
@include screen-below-mobile-820 {
  header .header-wrapper .center-items.search-container .search-dropdown .dropdown-menu-container {
    left: -$search-container-padding-horizontal / 2;
    height: $search-container-height * .8;
    width: calc(100% + ($search-container-padding-horizontal * .5));

    .dropdown-rows {
      margin-top: ($search-container-height * .8);
    }
  }
}