@import 'src/app/static/styles/main.scss';

.verify-email-page-layout {
padding-bottom: 600px;

  .header3 {
    text-align: center;
    margin: 100px 0px 110px;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
  }


}