@import 'src/app/static/styles/main.scss';


.product-details-page {
    .modal-content {
        @extend .order-summary-table;

        .make-scollable {
            position: relative;
            overflow-y: scroll;
            height: 50vh;

            .scollable-table {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        }

        .buttons-wrapper {
            display: flex;
            justify-content: space-between;
            width: 60%;
            margin-left: 40%;
            padding-top: 50px;

            .button {
                width: 48%;
            }
        }
    }

    .section {
        padding: 50px 80px;

        &.product-listing {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            gap: 5%;

            .img-wrapper {
                aspect-ratio: 2/1.25;
                background-size: contain;
            }

            .product-summary {
                h1 {
                    font-size: font-sizes(h2);
                    font-weight: font-weights(bold);
                    text-transform: uppercase;
                }

                p {
                    margin-top: 20px;
                }

                .brand,
                .vendor {
                    font-weight: font-weights(semi-bold);
                }
            }
        }

        &.order-info {
            .sub-header {
                margin-bottom: 20px;
                font-size: font-sizes(h6);
                font-weight: font-weights(semi-bold);
                text-transform: uppercase;
            }

            .order-options {
                display: flex;
                justify-content: start;
                margin-bottom: 50px;

                .dose-options {
                    margin-right: 50px;

                    ul {
                        display: flex;
                        justify-content: space-between;

                        li {
                            width: 60px;
                            margin-left: 10px;

                            &:first-of-type {
                                margin-left: 0;
                            }
                        }
                    }
                }

                .qty {
                    .order-submit {
                        display: flex;
                        justify-content: space-between;

                        .select-wrapper {
                            width: 60px;
                            margin-right: 10px;

                            select {
                                font-weight: font-weights(semi-bold);
                            }
                        }
                    }
                }
            }
        }

        &.info {
            h2 {
                text-transform: uppercase;
                color: colors(_blue);
                font-size: font-sizes(h2);
                font-weight: font-weights(bold);
            }

            &.use-description {
                background: rgba($color: #000, $alpha: 0.05);

                p {
                    margin-top: 20px;
                }
            }
        }
    }
}