.order-table {
    margin-bottom: 30px;
    min-width: 860px;

    &.smaller {
        min-width: 500px;
    }


    thead tr th span {
        font-weight: bold;
    }

    thead, tbody {
        cursor: default;
    }

    tbody td {
        position: relative; 
        overflow: visible;



        &.name-field {
            font-weight: 600;
        }

        &.quantity-field.quantity-field.quantity-field {
            overflow: visible;
        }

        // Using 'x' class here to increase specificity
        &.empty-card-placeholder.x.x.x {
            padding: 14px;
            background-color: rgba(0, 0, 0, .04);
            text-align: center;
            color: rgba(0, 0, 8, .35);
            font-weight: 600;
            font-style: italic;
            font-size: 1.6rem;
        }

        button.cart-change-button {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            border: 2px solid rgba(0,0,0,.1);
            border-radius: 50%;
            color: #334;
            cursor: pointer;
            opacity: .5;

            &.minus {
                left: 10px;
            }

            &.plus {
                right: 10px;
            }

            &.trash {
                display: none;
                left: -20px;
                height: 24px;
                width: 24px;
                margin-top: -2px;
                color: red;
                z-index: 999998;
            }

            &:hover {
                opacity: 1;
                background-color: rgba(0, 0, 8, .05);
            }

            &:active {
                background-color: rgba(0, 0, 16, .1);
            }

            &.trash:hover {
                border-color: #f00;
            }

            &.one-left:hover {
                color: red;
            }
        }
    }

    tr > *:hover {
        .trash.trash.trash.trash.trash.trash.trash {
            display: block;
            z-index: 100;
        }
    }
}