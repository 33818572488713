@import 'src/app/static/styles/main.scss';

.display-images {
  background-color: white;
}

.img1 {
  width: 530px;
}

.img2 {
  width: 530px;
}

.img3 {
  width: 530px;
}

.carousel-container1 {
  width: 550px;
  height: 330px;
  position: relative;
  margin: 0 auto;

}

.carousel1 {
  margin-top: 20px;
  transition: all 0.3s ease;

}

.carousel1 img {
  width: 100%;
  transition: all 0.3s ease;
  border: 8px solid white;
}


.item {
  position: absolute;
  display: none;
}

.main {
  display: block;
}


.ul-test {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  padding: 13px;
  padding-bottom: 60px;
}

.li-test {
  display: flex;
  background: rgba(141, 140, 140, 0.516);
  cursor: pointer;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  padding: 5px;
  justify-content: space-evenly;

  &:hover {
    background-color: colors(_purple);
  }
}

.dot-position {
  padding-right: 21px;
}

.dot-padding {
  padding-left: 8px;
}