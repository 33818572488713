$radio-button-size: 27px;

.div-contain-radio {

  .radio-label {
    display: grid;
    grid-template-columns: 15px auto;
    gap: $radio-button-size;
    font-family: 'Open Sans';
    font-size: 1.5rem;
    line-height: 1.9;
    padding-top: 6px;

    input[type='radio'] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      margin: 0;
      color: black;
      width: $radio-button-size;
      height: $radio-button-size;
      border: 1px solid black;
      border-radius: 50%;
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    input[type='radio']::before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1px 1px rgba(89, 190, 204, 255);
      /* Windows High Contrast Mode */
      background-color: rgba(89, 190, 204, 255);
    }

    input[type='radio']:checked::before {
      transform: scale(1);
    }
  }
}
