@import 'src/app/static/styles/main.scss';


.doctor-leaderboard-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 120px;
    padding-bottom: 40px;

      .header {
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
          color: black;

          &.blue {
              color: rgb(65, 118, 187);
          }

          &.small {
              font-size: 2rem;
          
              &.blue {
                color: rgb(65, 118, 187);
              }
          }
      }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;
    width: 100%;
    background-color: white;
    padding: 40px 23px;

    .header {
      text-align: left;
      font-size: 2.2rem;
      font-weight: bold;
      color: black;
    }

    .select-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 35px;
      margin-top: 45px;
      padding-bottom: 45px;

      .column {
        display: flex;
        flex-direction: column;
        width: 18%;
        gap: 15px;

        span {
          font-weight: 700;
        }

        &.date-range {
          width: 40%;
        }
      }
    }

    .record-text {
      margin-top: 55px;
    }

    .table-container {
      width: 100%;
      padding-bottom: 90px;
    }

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 50px;

      &.bottom-span {
        margin-top: 0px;
      }

   .left-box {
     display: flex;
     flex-direction: row;
     align-items: baseline;
     column-gap: 20px;

        .dropdown {
          width: 85px;
          height: 40px;
        }
      }
      
        .search-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: baseline;
          column-gap: 25px;

          span {
            font-weight: bold;
          }
        }
    }
  }
}