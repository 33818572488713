@import 'src/app/static/styles/main.scss';




@keyframes tonext {
  75% {
    left: 0;
  }

  95% {
    left: 100%;
  }

  98% {
    left: 100%;
  }

  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }

  95% {
    left: -300%;
  }

  98% {
    left: -300%;
  }

  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }

  97% {
    scroll-snap-align: none;
  }

  99% {
    scroll-snap-align: none;
  }

  100% {
    scroll-snap-align: center;
  }
}


/////======================================================


*,
*:before,
*:after {
  box-sizing: border-box;
}



.slide-body {
  height: 340px;
  color: #444;
  font-size: 1rem;
  line-height: 1.5;
}

.slider {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider__nav {
  background-color: grey;
  width: 12px;
  height: 12px;
  margin: 2rem 12px;
  border-radius: 50%;
  z-index: 10;
  outline-offset: -6px;
  box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slider__nav:checked {
  background-color: purple;
  color: purple;
}

.slider__nav:checked:nth-of-type(1)~.slider__inner {
  left: 0%;
}

.slider__nav:checked:nth-of-type(2)~.slider__inner {
  left: -100%;
}

.slider__nav:checked:nth-of-type(3)~.slider__inner {
  left: -200%;
}

.slider__nav:checked:nth-of-type(4)~.slider__inner {
  left: -300%;
}

.slider__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 400%;
  height: 100%;
  -webkit-transition: left 0.4s;
  transition: left 0.4s;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.slider__contents {
  height: 100%;
  padding: 2rem;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider__image {
  font-size: 2.7rem;
  color: #2196F3;
}

.slider__caption {
  font-weight: 500;
  margin: 2rem 0 1rem;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
}

.slider__imgsize {
  margin-bottom: 3rem;
  max-width: 300px;
}

@-webkit-keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgba(197, 22, 22, 0.952);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgb(250, 0, 0);
  }
}

@keyframes check {
  50% {
    outline-color: #333;
    box-shadow: 0 0 0 12px #333, 0 0 0 36px rgb(255, 0, 0);
  }

  100% {
    outline-color: #333;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgb(255, 0, 0);
  }
}