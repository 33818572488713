@import 'src/app/static/styles/main.scss';

.pill-button {
  display: flex;
  justify-content: space-between;
  column-gap: 25px;
  align-items: center;
  width: 'fit-content';
  font-family: 'Open Sans';
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 12px 10px 20px;
  border-radius: 25px;
  transition: all 0.2s;
  cursor: pointer;

  &.reversed {
   padding: 10px 28px 10px 12px;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: all;
    box-shadow: none;
  }

  .custom-icon {
    color: colors(_purple);
    width: font-sizes(button) * 1.2;
    height: font-sizes(button) * 1.2;
    transition: all 0.2s;
  }

  .cross-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: font-sizes(button) * 1.2;
    height: font-sizes(button) * 1.2;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }

  .add {
    width: 0.83em;
    height: 0.83em;
    clip-path: polygon(
      0 39%, 
      40% 39%, 
      40% 0, 
      61% 0, 
      61% 39%, 
      100% 39%, 
      99% 59%, 
      61% 59%, 
      61% 100%, 
      40% 100%, 
      40% 59%, 0 59%
      );
    box-shadow: inset 26.4px 26.4px #457ebe;
  }

  &.white {
    background: #fff;
    color: #000;
    box-shadow: 0px 8px 8px 2px rgba(84, 162, 230, 0.203);

    &.gradient {
      color: white;
      background: #457ebe;
      background: linear-gradient(140deg, #457ebe 33%, #5abdcc 70%);
      box-shadow: none;

      .custom-icon {
        color: white;
      }

      &:hover {
        background: #457ebe;
        background: linear-gradient(140deg, #5abdcc 1%, #5abdcc 99%);
        color: white;
        box-shadow: 3px 3px 6px 1px rgba(78, 98, 190, 0.229);

        .custom-icon {
          color: white;
        }
      }

      &:hover {
        background: #457ebe;
        background: linear-gradient(140deg, #5abdcc 1%, #5abdcc 99%);
        color: white;
        box-shadow: 3px 3px 6px 1px rgba(78, 98, 190, 0.229);
      }

      &:active {
        background: #fff;
        color: white;
        background: #457ebe;
        background: linear-gradient(140deg, #457ebe 33%, #5abdcc 70%);
        box-shadow: 3px 3px 6px 1px rgba(78, 98, 190, 0.229);
        box-shadow: none;
        transition: all 0.2s;

        .add {
          box-shadow: inset 26.4px 26.4px #457ebe;
        }
      }
    }

    &:hover {
      color: #fff;
      background-color: colors(_purple);
      box-shadow: 0px 8px 8px 2px rgba(84, 162, 230, 0.203);

      .custom-icon {
        color: #fff;
      }

      .add {
          box-shadow: inset 26.4px 26.4px #5abdcc;
        }
      }

    &:active {
      background: #fff;
      color: #000;
      padding-left: 23px;
      padding-right: 9.5px;
      box-shadow: 0px 8px 8px 2px rgba(84, 162, 230, 0.203);
      transition: all 0.2s;

      
          .custom-icon {
            color: colors(_purple);
            transition: all 0.2s;
          }
              
      
      &.reversed {
        padding-left: 10px;
        padding-right: 30px;
      }
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.05);
      color: #fff;
      box-shadow: none;

      .custom-icon {
        color: #fff;
        transition: all 0.2s;
      }
    }
  }
}
