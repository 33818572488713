@import 'src/app/static/styles/main.scss';

.modal {
  font-family: 'Open Sans';
  font-size: 1.5rem;

  .modal-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 20px;
    // padding-top:
    // height: 120px;
    // background-color: #f3f4f4;

    .bold-text {
      padding-top: 105px;
      padding-bottom: 30px;
      font-weight: bold;
      font-size: 2rem;

      &.two {
        padding-top: 0px;
        padding-bottom: 20px;
        font-weight: bold;
        font-size: 2rem;
      }
    }

    .standard-text {
    }

    .button-group {
    //  height: 50px;
    }

    .button-style {
      height: 50px;
    }

 //   .image-here {
 //     padding-left: 30px;
 //     margin-top: 30px;
 //     padding-right: 20px;
 //     display: flex;
 //     align-items: center;
 //     font-size: 30px;
 //     width: 100px;
 //     height: 100px;
 //   }
  }
}
