@import 'src/app/static/styles/main.scss';
.search-input-field-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    .input-field {
        width: calc(100% - $header-icon-size-search);
        padding-right: 4px;
        border: none;
        background-color: transparent;
        padding-left: 15px;
    }
    .icon-submit {
        padding: 0;
        border: none;
        background: transparent;
        width: $header-icon-size-search;
        cursor: pointer;
        svg {
            width: $header-icon-size-search;
            height: $header-icon-size-search;
        }
    }
}
.search-input-results-box {
    position: absolute;
    margin-top: 50px;
    padding: 0px;
    border-radius: 8px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border: 3px solid rgba(0, 8, 32, 0.05);
    width: calc(100% + 20px);
    height: auto;
    background-color: rgba(220, 227, 240, .99);
    height: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;;
    transition-duration: 300ms;
    z-index: 13; // needs to go over nav bar which is 11.
    opacity: 0;
    box-shadow: 0px 60px 60px rgba(0, 4, 16, 0.3);

    &.show {
        padding: 12px;
        height: fit-content;
        opacity: 1
    }
    .search-results-box-title-bar {
        padding-left: 4px;
        display: flex;
        justify-content: space-between;

        .search-results-box-title {
            font-size: 1.2rem;
            color: #667;
            cursor: default;
        }
        .search-results-box-close-button {
            margin: -4px -4px 0 0;
            padding: 6px;
            border-radius: 4px;
            font-size: 1.2rem;
            color: #667;
            &:hover {
                background-color: rgba(0, 4, 16, 0.1);
            }
        }
        // Developer warning - only visible on local env.
        .dev-warning {
            position: absolute;
            top: -16px;
            color: red;
            font-size: 10px;
            font-weight: bold;
            text-align: center;
        }
    }
    
    .search-result-section-title {
        margin: 12px 0;
        padding: 4px;
        border-radius: 2px;
        border-bottom: 1px solid rgb(200,202,206, .4);
        background-color: rgba(250, 251, 255, .2);
        color: #556;
        font-size: 1.2rem;
        font-weight: 600;
        cursor: default;
    }
    
    .search-result-row {
        position: relative;
        margin: 2px 0;
        padding: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;
        transition-duration: 150ms;

        .thumbnail-container {
          height: 50px;
          width: 50px;
          border: 2px solid #334;

          .thumbnail-img {
              height: 100%;
              width: 100%;
          }
        }

        &:hover {
            background-color: rgba(250, 251, 255, .5);
            box-shadow: 0px 10px 20px rgba(0, 4, 16, 0.3);
        }
        

        .business-details {
            font-size: 1.4rem;
            line-height: 1.4;
            .business-title {
                font-weight: bold;
            }
            .business-body {
                font-size: 1.2rem;
            }
        }
        .clinic-details {
            font-size: 1.4rem;
            line-height: 1.4;
            .clinic-title {
                font-weight: bold;
            }
            .clinic-subtitle {
                font-size: 1.2rem;
            }
            .clinic-body {
                font-size: 1.2rem;
                color: #667;
            }
        }
        .product-details {
            font-size: 1.4rem;
            line-height: 1.4;
            .product-title {
                font-weight: bold;
            }
            .product-body {
                font-size: 1.2rem;
            }
        }
    }
}
/** 
 * Responsive styling
 */
 @include screen-below-mobile-820 {
    .input-field-wrapper {
        .input-field {
            width: calc(100% - 5px);
            font-size: 1.4rem;
        }
        .icon-submit {
            display: none;
            width: $header-icon-size-search * .5;
        }
    }
}