@import 'src/app/static/styles/main.scss';


.searchresults-layout {
  
  .header {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    padding: 50px 0px 80px;;
  }

  .formwrapper-top {
    display: flex;
    flex-direction: row;
    gap: 5%;

    .left-panel {
      width: 30%;
      height: 900px;
     
      .filter-keyword {
        width: 90%;
      }

      .check-filters {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: 25px;

          .closable-circles {
            display: flex;
            flex-direction: row;
            text-align: center;
            gap: 26%;
            margin-bottom: -20px;
            margin-left: 80px;
            width: 104%;
            z-index: 2;

            .circle-x {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              background-color: black;
              cursor: pointer;

              .x-itself {
                clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
                background-color: white;
                width: 14px;
                height: 14px;
              }
            }
          }

        .filter-boxes {
          display: flex;
          flex-direction: row;
          text-align: center;
          gap: 7%;
        

        .filter {
          width: 30%;
          padding: 13px;
          border: 3px solid colors(light-grey);
          border-radius: 5px;
          cursor: pointer;
        }
      }
      }

      .expansion-container {
        display: flex;
        width: 100%;
        margin-top: 70px;
        
        details {
          user-select: none;
          width: 90%;

          .expansion-box {
            display: flex;
            flex-direction: column;
            gap: 50px;
            border-radius: 5px;
            background-color: colors(light-grey);
            width: 100%;
            margin-top: 20px;
            padding: 25px 15px 50px;
            font-weight: 600;
            
            .checkone {
              display: flex;

              .text {
                display: flex;
                flex-direction: column;
                width: 200px;
                font-size: 1.8rem;
                margin-top: -4px;
              }
            }
          }
        }
        
        details>summary span.arrow {
          clip-path: polygon(37% 49%, 100% 100%, 100% 0);
          background-color: black;
          width: 17px;
          height: 17px;
          transition: all 0.3s;
          margin-left: auto;
        }
        
        details[open] summary span.arrow {
          transform: rotate(-90deg);
          transition: all 0.3s;
        }
        
        summary {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 2.4rem;
          font-weight: bold;
          display: flex;
          cursor: pointer;
        }
        
        summary::-webkit-details-marker {
          display: none;
        }
      }
    }

    .right-results {
      display: flex;
      flex-direction: column;
      width: 60%;
    }


  }
  .page-numbers {
    padding: 90px 0px 70px;
  }
}