@import 'src/app/static/styles/main.scss';

$left-col-min-width: 200px;
$middle-col-min-width: 400px;
$right-col-min-width: 400px;
$table-min-width: calc($left-col-min-width + $middle-col-min-width + $right-col-min-width);

/**
 * Overlay on top of DesktopHeader component for smooth UI experience when navigating to new page.
 */
.admin-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 50;
    overflow: scroll;
}
// Underlay for the header so navigation transitions aren't as obvious.
.admin-page-header-underlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    background-color: #def;
    z-index: 55;
}

/**
 * For all regular admin page components.
 */
.body-container-admin {
    position: relative;
    margin: 0 auto;
    padding: 150px 0 360px;
    width: calc(100vw - 100px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 40px;
    height: auto;
    z-index: 55;

    .page-title {
        font-size: 3rem;
        font-weight: bold;
        color: colors(_blue);
    }

    .section {
        padding: 20px 40px 40px;
        height: auto;
        background-color: rgba(0, 0, 0, .1);
        border-radius: 12px;
        overflow: hidden;

        &.table-section {
            overflow-x: scroll;
        }

        .title {
            margin-top: 20px;
            font-size: 2rem;
            font-weight: bold;
            color: colors(_blue);
        }

        .subsection {
            margin-top: 12px;
            padding: 12px;
            height: auto;
            border: 1px solid rgba(0, 4, 12, .1);
            border-radius: 12px;

            > .title {
                margin-top: 0px;
                font-size: 16px;
                font-weight: 700;
                color: rgba(100, 120, 250, .9);
            }
        }

        .subtitle {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 600;
            color: colors(_purple);
        }

        .description {
            margin-top: 40px;
            font-size: 1.6rem;
            font-weight: 600;
            color: rgba(0,0,16, .8);
        }

        .radio-row {
            margin-top: 12px;
            font-size: 16px; 
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 8px;

            .title {
                margin-top: 0;
                color: colors(_purple);
                font-weight: 600;
                font-size: 14px; 
                display: flex;
                align-items: flex-start;
                flex-grow: 0;
                flex-shrink: 0;

                &.short {
                    width: 140px;
                    margin-left: 20px;
                }
            }
            .options {
                display: flex;
                align-items: center;
                row-gap: 8px;
                flex-wrap: wrap;

                input[type="text"] {
                    padding: 6px 12px;
                    width: 500px;
                    max-width: 60%;
                    background-color: #fff;
                    border-radius: 4px;
                    font-family: 'Open Sans';
                    font-size: 1.6rem;
                    font-weight: 600;
                    color: rgba(0, 4, 12, .7);
                    letter-spacing: 1px;

                    &.short {
                        width: 250px;
                    }

                    &::placeholder {
                        color: #aaa;
                    }

                    // For non-empty fields
                    &:not(.default-10):not(:placeholder-shown) {
                        outline: 2px solid #00f;
                    }

                    // For when value != 10
                    &.default-10:not(:valid[value="10"]) {
                        outline: 2px solid #00f;
                    }
                }

                input[type="radio"] {
                    display: none;

                    // For selected fields
                    &:not(.default):checked + label {
                        outline: 2px solid #00f;
                    }
                }
                label {
                    margin-right: 10px;
                    padding: 5px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    cursor: pointer;
                }
                input[type="radio"]:checked + label {
                    background-color: #ccc;
                }
            }
        }
        
        .row {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            column-gap: 40px;

            .input-wrapper {
                margin-top: 20px;
            }
        }

        .table-container {
            border-top: 1px solid #aaa;
            border-bottom: 1px solid #aaa;
            width: fit-content;
            overflow-x: scroll;
            cursor: default;

            .table-row {
                font-size: 16px;
                color: black;
                font-weight: normal;
                font-family: monospace;
                word-wrap: break-word;
                display: flex;
                cursor: pointer;

                &:hover,
                &:focus { 
                    background-color: rgba(0, 0, 0, .05);
                }
                &:active { 
                    background-color: rgba(0, 0, 0, .1);
                }

                &.title {
                    font-weight: bold;
                    color: blue;
                    border-bottom: 1px solid #aaa;
                    margin-top: 0;
                    cursor: default;
                }

                &.scrollable-cells .table-cell {
                    overflow-x: scroll;
                }
                &.nowrap-cells .table-cell {
                    white-space: nowrap;
                }

                .table-cell {
                    padding: 4px;
                    text-align: left;
                    overflow: scroll;
                    max-height: 100px;
                    flex-shrink: 0;
                    border-right: 1px solid #aaa;

                    &:first-child {
                        border-left: 1px solid #aaa;
                    }

                    // Sizes
                    &.a { width: 40px; }
                    &.b { width: 70px; }
                    &.c { width: 160px; }
                    &.d { width: 300px; }
                    &.e { width: 420px; }

                    &.scroll {
                        overflow-x: scroll;
                    }
                    &.nowrap {
                        white-space: nowrap;
                    }
                }
            }
        }

        .data-fetched-at-note {
            font-size: 14px;
            color: #778;

            &.top {
                transform: translateY(-20px);
            }
            &.bottom {
                transform: translateY(20px);
            }
        }
    }

    .preload-spinner-in-button {
        display: inline-block;
        margin: 0 -28px -4px 8px;
        height: 20px;
        width: 20px;
    }

    .big-spinner-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        transition-duration: 400ms;
        z-index: 70; // needs to be above the header/nav-bar

        &.show {
            background-color: rgba(0, 0, 0, .2);
        }
        .preload-spinner-big {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 120px;
            width: 120px;
        }
    }

    .row-details-modal, 
    .change-log-modal {
        min-width: $table-min-width;
        
        .modal-rows-container {
            border: 1px solid #ddd;
            white-space: pre-wrap;
            min-width: $table-min-width;

            &.change-log {
                min-width: unset;
            }

            .modal-row { 
                position: relative;
                border-bottom: 1px solid #ddd;
                height: auto;
                display: flex;
                font-size: 16px;
                min-width: $table-min-width;

                &.change-log {
                    border-bottom: 2px solid #ccc;
                }

                &.title-row {
                    line-height: 24px;
                    background-color:#def;
                    font-weight: bold;

                    &.change-log {
                        background-color: #dddde6;
                        // border: 1px solid #779;
                    }

                    * {
                        padding: 6px 12px !important;
                    }
                }

                &.change-log {
                    min-width: unset;
                }

                .left {
                    padding: 0 0 0 12px;
                    width: fit-content;
                    min-width: $left-col-min-width;
                    border-right: 1px solid #000;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .icon-wrapper {
                        // display: inline-block;
                        margin: 3px 0 0 8px;
                        padding: 4px;
                        border-radius: 50%;
                        height: 22px;
                        width: 22px;
                        cursor: pointer;

                        svg {    
                            color: colors(_blue);
                        }

                        &.red svg {
                            // color: #000; // using black instead of red - draws attention but not overt.
                            // Nvm use same blue for now.
                            color: colors(_blue);
                        }

                        &:hover {
                            background-color: rgba(0, 0, 0, 0.1);
                        }
                    }

                    &.change-log {
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding-top: 8px;
                        padding-bottom: 20px;
                        width: 200px !important;
                        min-width: unset;
                        flex-direction: column;
                        line-height: 1.2;
                    }
                }

                .middle {
                    position: relative;
                    // padding: 12px 12px 6px 12px;
                    padding: 8px;
                    min-height: 32px;
                    width: 50%;
                    min-width: $middle-col-min-width;
                    overflow: scroll;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: stretch;

                    &.stack {
                        justify-content: flex-start;
                    }

                    button.expand-item-button {
                        position: absolute;
                        padding: 4px;
                        top: 50%;
                        right: 12px;
                        transform: translateY(-50%);
                        height: 28px;
                        width: 28px;
                        border-radius: 5px;
                        border: 2px solid #acf;
                        outline: 1px solid #fff;
                        transition-duration: 200ms;
                        cursor: pointer;
    
                        svg {    
                            color: rgba(255,255,255,1);
                        }

                        &:hover {
                            background-color: #bcd;;
                            padding: 1px;
                        }

                        &:active {
                            background-color: #9ab;
                        }
                    }
                }

                .middle-split { // Used for change-log rows
                    padding: 16px 0;
                    height: auto;
                    width: calc(100% - 100px) !important;
                    display: flex;
                    flex-direction: column;

                    .middle-split-inner-row {
                        padding: 4px 0;
                        display: flex;
                        flex-direction: row;

                        .split-left {
                            padding: 4px 16px 4px 16px;
                            width: 50%;
                            border-right: 1px solid #99a;
                            display: flex;
                            flex-direction: column;
                        }

                        .split-right {
                            padding: 4px 16px 4px 16px;
                            width: 50%;
                            display: flex;
                            flex-direction: column;
                        }

                        .old-key {
                            margin-bottom: 16px;
                            // transform: translate(-16px, 0px);
                            font-weight: bold;
                            color: #aab;
                        }
                        .old-val {
                            max-width: 400px; // to keep lines the same length bc left/right aren't perfectly even.
                            font-family: monospace;
                            // color: #f43;
                            color: #001;
                        }
                        .new-key {
                            margin-bottom: 16px;
                            // transform: translate(-16px, 0px);
                            font-weight: bold;
                            color: #aab
                            // user-select: none;
                            // color: transparent;
                        }
                        .new-val {
                            max-width: 400px; // to keep lines the same length bc left/right aren't perfectly even.
                            font-family: monospace;
                            // color: #2b5;
                            color: #001;
                        }

                        .old-val .envelop,
                        .new-val .envelop {
                            padding: 4px;
                            background-color: #d8dadd;
                            border-radius: 4px;
                        }

                        .envelop .blue-highlight {
                            background-color: #8cf;
                        }
                    }
                }
                
                .right {
                    padding: 4px 0 0 12px;
                    width: 50%;
                    min-width: $right-col-min-width;
                    display: flex;
                    justify-content: stretch;
                    align-items: center;
                    
                    .data-details {
                        font-size: 14px; // more rows of data need to fit in here.
                        line-height: 20px;
                        color: rgba(0, 4, 12, .7);
                    }

                    &.change-log {
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding-top: 8px;
                        padding-left: 32px;
                        width: calc(50% - 50px) !important;
                        min-width: unset;
                    }
                }

                input.edit-string {
                    margin-bottom: 0;
                    background-color: rgba(0, 0, 0, .1);
                    border-radius: 4px;
                }
                select.edit-select {
                    background-color: rgba(0, 0, 0, .1);
                    border-radius: 4px;
                    width: 100px;
                }
                
                .mock-link-wrapper {
                    padding: 16px 0;
                    line-height: 1.5;

                    &.chank-log {
                        padding-top: 8px;
                        line-height: 1.2;
                    }
                    
                    .mock-link {
                        padding: 4px;
                        width: fit-content;
                        border-radius: 4px;
                        color: colors(_blue);
                        font-weight: 600;
                        cursor: pointer;
                        transition-duration: 200ms;

                        &:hover {
                            color: #305080;
                            background-color: rgba(222, 238, 255, .5);

                        }

                        &:active {
                            background-color: rgba(222, 238, 255, .9);
                        }
                    }
                }
            }
        }

        .modal-bottom-buttons-row {
            margin-top: 100px;
            display: flex;
            justify-content: space-between;
        }
    }

    .loading-box,
    .success-box,
    .error-box {
        position: fixed;
        bottom: -50px;
        left: 0;
        padding: 20px;
        height: 0px;
        width: 100%;
        background-color: rgb(220, 224, 236);
        z-index: 20;
        overflow: scroll;
        transition-duration: 200ms;

        &.show {
            bottom: 0;
            height: 300px;
        }
    }

    .loading-box {
        bottom: -50px;
        border-top: 5px solid blue;
        opacity: .7;

        &.show {
            height: 60px;
        }
    }

    .success-box {
        border-top: 5px solid green;
    }

    .error-box {
        border-top: 5px solid red;
    }

    .error-message {
        margin-top: 20px;
        font-size: 1.6rem;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;

        &.collapse {
            margin-top: 0;
        }
    }
}