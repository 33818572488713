@import 'src/app/static/styles/main.scss';

.cart-check-out-confirmation {
    .title-order-id {
        margin-top: 14px;
        text-transform: uppercase;
        font-size: 2.2rem;
        font-weight: bold;
        color: colors(_blue);
    }

    .delivery-info-container {
        margin: 100px 0 60px;
        padding-bottom: 60px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        border-bottom: 2px solid colors(_black, 0.08);

        .left {
            display: flex;
            flex-direction: column;

            .delivery-address-title {
                font-size: 2rem;
                text-transform: uppercase;
                font-weight: 700;
            }
            .delivery-address {
                margin-top: 16px;
                font-size: 1.6rem;
                line-height: 1.3;
                font-weight: 600;
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            text-align: right;

            .delivery-date-title {
                font-size: 1.8rem;
                text-transform: uppercase;
                font-weight: 600;
            }
    
            .delivery-date {
                margin-top: 16px;
                font-size: 1.6rem;
                font-weight: 600;
            }
        }
    }

    .shipments-by-date {
        .shipment-counter-title {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;

            .left {
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: bold;
            }

            .right {
                font-size: 1.6rem;
                font-weight: 400;

            }
        }

        .orders-table-wrapper {
            @extend .order-summary-table;
            margin-top: 12px;
            padding-bottom: 30px;
            border-bottom: 2px solid colors(_black, 0.08);
            overflow-x: scroll;
        }
    }

    .notes {
        margin: 50px 0 30px;
        padding-bottom: 50px;
        border-bottom: 2px solid colors(_black, 0.08);

        .notes-title {
            margin-left: 12px;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: bold;
        }

        .notes-text {
            padding: 12px;
            margin-top: 12px;
            border-radius: 6px;
            background-color: rgba(0, 0, 0, .05);
            font-size: 1.4rem;
            line-height: 1.25;
            white-space: pre-wrap;
            cursor: default;

            .notes-none-text {
                opacity: .8;
            }
        }
    }

    .totals-container {
        margin: 50px auto 0;
        text-align: center;;

        .total-price {
            font-size: 3.2rem;
            font-weight: 600;
            color: colors(_blue);
        }

        .total-price-subtext {
            margin-top: 8px;
            font-size: 1.4rem;
            font-weight: 600;
            color: rgba(0, 0, 8, .9)
        }

        .payment-method {
            margin-top: 36px;
            font-size: 1.4rem;
            font-weight: 600;
            color: rgba(0, 0, 8, .9)
        }
    }

    .buttons-container-finaize {
        display: flex;
        justify-content: space-between;
        flex-wrap: no-wrap;
        row-gap: 8px;
        column-gap: 24px;
        margin: 75px 0 10px;

        .left {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            flex-wrap: wrap;
            row-gap: 8px;
            column-gap: 24px;
        }

        .right {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex-wrap: wrap;
            row-gap: 8px;
            column-gap: 24px;
        }
    }
}


