.clickable {
    cursor: pointer;
}

.h1 {
    font-weight: bold;
    font-size: font-sizes(h1);
    line-height: 7.2rem;
    text-transform: capitalize;
}

.h2 {
    font-size: font-sizes(h2);
    font-weight: bold;
    line-height: 3rem;
    text-transform: uppercase;
}

.h3 {
    font-size: font-sizes(h3);
    font-weight: bold;
    line-height: 2.625rem;
    text-transform: uppercase;
}

.h4 {
    font-size: font-sizes(h4);
    font-weight: bold;
    line-height: 2.25rem;
    text-transform: uppercase;
}

.h5 {
    font-size: font-sizes(h5);
    font-weight: bold;
    line-height: 2.25rem;
    text-transform: uppercase;
}

.body {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 2rem;
    text-transform: none;
}

.form {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0rem;
    line-height: 2.25rem;
    text-transform: none;
}

.button {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.25rem;
    text-transform: uppercase;
}

.link {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0rem;
    line-height: 2.25rem;
    text-transform: none;
}

.menu {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 2.25rem;
    text-transform: uppercase;
}
