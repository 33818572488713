.carousel-body {
  display: flex;
  align-items: center;
  font-family: 'Open Sans';
  margin-right: 160px;

  .carousel-container {
    position: static;
  //  height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.4s ease-in;
    background-color: rgb(243, 243, 243);
  }

  input[type='radio'] {
    display: none;
  }

  .card {
    position: absolute;
    transition: transform 0.4s ease;
  }

  .carousel-place-card {
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .cards {
    width: 100%;
  }

  #item-1:checked ~ .cards #display-3,
  #item-2:checked ~ .cards #display-1,
  #item-3:checked ~ .cards #display-2 {
    transform: translatex(0%) scale(0.9);
    opacity: 1;
    z-index: 0;
  }

  #item-1:checked ~ .cards #display-2,
  #item-2:checked ~ .cards #display-3,
  #item-3:checked ~ .cards #display-1 {
    transform: translatex(21.7%) scale(0.9);
    opacity: 1;
    z-index: 0;
  }

  #item-1:checked ~ .cards #display-1,
  #item-2:checked ~ .cards #display-2,
  #item-3:checked ~ .cards #display-3 {
    transform: translatex(10.7%) scale(1);
    opacity: 1;
    z-index: 1;
  }

  .slider {
    background-color: transparent;
    min-width: 320px;
  }

  .image {
    object-fit: contain;
  }

  .item-list {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    .item-li-group {
      list-style: none;
      color: black;

      &.doctor-name {
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: bold;
      }

      &.career-field {
        margin-top: -33px;
        font-size: 1.6rem;
        font-style: italic;
        font-weight: 600;
      }

      &.description-container {
        height: 50%;
        width: 100%;
      //  padding-bottom: -10px;
        text-overflow: ellipsis;
      }

      &.description {
        line-height: 2.3rem;
        font-size: 1.5rem;
        width: 90%;
        height: 85px;
        word-break: break-word;
        text-overflow: ellipsis;
      }
    }
  }

  .button-placement {
    margin: -110px 0px 0px 410px;
  }

  .test-space {
    top: 50px;
    width: 500px;
    height: 700px;
    background-color: grey;
    z-index: 99;
  }
}