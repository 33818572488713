@import 'src/app/static/styles/main.scss';

.mainbutton {
  width: 170px;
  height: 45px;
  //padding: ;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: orange;
  background-color: black;
  border: 1px solid black;
  border-radius: 7px;
  outline: none;
  cursor: pointer;
  transition: all 0.7s;
  //gradient: ;
  //icon: ;

  
    &:hover {
  background-color: orange;
  color: black;
  border: 1px solid orange;
}
}

.icon {
  padding-right: 20px;
  width: 4vmin;
  color: orange;

  &:hover {
    background-color: black;
    color: black;
  }
}

.icon-padding {
  margin-right: 40px;
}