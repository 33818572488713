@import 'src/app/static/styles/main.scss';

.generic-modal.change-log-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 92; // needs to be higher than data modal, which is set to 90.

  .close-button {
    position: fixed;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: .2;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  
    &:hover,
    *:focus {
      opacity: .3;
    }
  }

  .modal-contents {
    height: 100%;
    width: 100%;
  }

  .bg-shade {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    z-index: 2;
    background: white;
    top: calc(50vh + 50px);
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 50px;
    border-radius: 5px;
    max-height: calc(90vh - 100px);
    width: 90vw;
    overflow: scroll;

    .over-title {
      margin-top: -20px;
      margin-bottom: 2rem;
      padding: 4px 0;
      // background-color: #def;
      text-align: center;
      font-size: 2rem;
      font-weight: 700;
      font-family: monospace;
      color: #aab;
      color: colors(_blue);
      text-transform: uppercase;
      cursor: default;
    }
    
    .title {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2.8rem;
      font-weight: 700;
      color: colors(_blue);
      text-transform: uppercase;
      font-family: 'Open Sans';
    }

    .top-left-text {
      position: absolute;
      top: 25px;
      left: 50px;
      font-size: 2rem;
      font-weight: 700;
      font-family: monospace;
      color: #aab;
      text-transform: uppercase;
      cursor: default;
    }

    .subtitle {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
      font-style: italic;
      white-space: pre-line;
    }

    .subtitle2 {
      margin: -1rem 0 2rem 50%;
      transform: translateX(-50%);
      width: fit-content;
      text-align: center;
      font-size: 1.4rem;
      font-style: italic;
      color: #889;
      cursor: default;
    }

    .subtitle-mock-link {
      margin: -1rem 0 1rem 50%;
      padding: 8px;
      transform: translateX(-50%);
      width: fit-content;
      border-radius: 4px;
      text-align: center;
      font-size: 1.4rem;
      color: #889;
      cursor: pointer;
      transition-duration: 200ms;

      &:hover {
        background-color: rgba(0, 0, 0, .07);
        color: #556;
      }
    }

    .subtitle-links {
      margin-top: -1rem;
      margin-bottom: 2rem;
      text-align: center;
      display: flex;
      justify-content: center;
      column-gap: 30px;
      
      button.button-link {
        padding: 8px;
        height: 42px;
        width: 42px;
        border-radius: 6px;
        color: rgba(0, 4, 12, .4);
        cursor: pointer;
        transition-duration: 200ms;

        &:hover {
          color: rgba(0, 4, 22, .8);
          background-color: rgba(222, 238, 255, .5);
        }
      }
    }
  }
}
