@import 'src/app/static/styles/main.scss';
//-----------------------------

.med-prac-button {
  font-family: font-families(base);
  font-weight: bold;
  font-size: font-sizes(button);
  text-align: center;
  text-transform: uppercase;
  border: 3px solid #070d27;
  border-radius: 4px;
  cursor: pointer;
  color: #070d27;
  background-color: white;
  width: 310px;
  height: 137.5px;

  &:hover {
    background-color: #070d27;
    color: white;
  }

  .custom-icon-container {
    padding-top: 6.5px;
  }

  .custom-icon {
    //    margin-top: -1px;
    //   margin-left: 44px;
    //  margin-right: 5px;
    transition: all 0.2s;
  }
}
