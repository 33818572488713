@import 'src/app/static/styles/main.scss';


.forgot-password-page-layout {

        .main-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 60px;
            width: 100%;

            .header {
                font-size: 3rem;
                font-weight: bold;
                color: rgb(65, 118, 187);
            }

            .progress-bar-placement {
                width: 300px;
                display: flex;
                justify-content: center;
                margin: 10px 0px 20px;
            }

            .form-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                width: 36%;
                padding-bottom: 100px;

                &.bottom {
                    margin-top: 20px;
                    padding-bottom: 80px;
                }
            }

            .text-spacing {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;

                span {
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: red;
                }
            }
        }
};