@import 'src/app/static/styles/main.scss';


.text-card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 60px;
    column-gap: 60px;

    .product-detail-modal-container {
        .title {
            min-width: 400px;
            font-size: 2.4rem;
            font-weight: bold;
            color: colors(blue-grey);
            text-align: center;
        }

        .table {
            display: grid;
            grid-template-columns: 3.4fr 2fr 2fr 1.5fr;
            margin: 0px auto 0;
            width: 100%;

            .table-header {
                padding: 16px 4px;
                color: colors(_blue);
                font-size: 2rem;
                font-weight: bold;
                text-align: left;

                &.unit-header,
                &.add-to-cart {
                    text-align: center;
                }
            }

            .table-field {
                padding: 12px 4px;
                color: colors(blue-grey);
                font-size: 2rem;
                text-align: left;
                // font-weight: 600;
                
                &.unit-field {
                    text-align: center;
                }

                &.add-to-cart-button-field {
                    display: flex;
                    justify-content: center;

                    button {
                        display: flex; 
                        align-items: center;
                        justify-content: center;
                        margin-top: -4px;
                        padding: 0;
                        height: 30px; 
                        width: 30px; 
                        font-size: 28px;
                    }
                }
            }
        }
    }

    .carousel-camera-roll {
        width: 500px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .text-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 1;
        flex-grow: 1;
        height: 100%;
        width: min-content;
        max-width: 600px;
        min-width: 300px;

        .item-list {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            // width: 100%;
          
            .item-li-group {
                list-style: none;
                color: black;
                font-size: 1.5rem;
                font-weight: 700;

                &.productname {
                    font-size: 2.3rem;
                    font-weight: bold;
                }

                &.brandname {
                    padding-top: 2.5%;
                    font-weight: 600;
                }

                &.vendorname {
                    font-weight: 600;
                }

                &.productdescription {
                    font-weight: 100;
                    padding-top: 15px;
                    line-height: 25px;
                    white-space: pre-line;
                }
            }
        }
    }
}
