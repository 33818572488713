@import 'src/app/static/styles/main.scss';

$spacing: 56px;
$spacing-small: 24px;

.manualcarousel-content-container {
  display: flex;
  justify-content: space-between;
  column-gap: $spacing;
  padding: $spacing;
  width: 100%;
  height: 100%;
  max-height: 450px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 15px 20px 3px rgba(117, 117, 117, 0.195);

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;

    img {
      // object-fit: cover;
      width: 100%;
      align-self: flex-start;
    }
  }


  .item-list {
    display: flex;
    flex-direction: column;
    width: 60%;

    .name-and-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 6rem;

      .doctor-name {
        text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: bold;
      }
  
      .career-field {
        font-size: 1.4rem;
        font-style: italic;
        font-weight: 600;
      }
    }

    .description-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 6rem;
      text-overflow: ellipsis; // check
      flex-grow: 1;

      .description,
      .description-small {
        line-height: 2rem;
        font-size: 1.4rem;
        letter-spacing: .05rem;
        word-break: break-word;
        text-overflow: ellipsis;
      }

      .description { display: block; }
      .description-small { display: none; }
      .description-vsmall { display: none; }
    }
  }

  /**
   * ====================================================================================
   * Media queries for responsiveness
   * ====================================================================================
   */
  @include screen-md-and-below {
    &.manualcarousel-content-container {
      padding: $spacing-small;

      .image-container {
        width: 50%;
      }

      .item-list {
        margin-top: 1rem;
        width: 50%;

        .description-container {
          margin-top: 6rem;
          height: 4rem;

          .description { display: none; }
          .description-small { display: block; }
          .description-vsmall { display: none; }
        }
      }
    }

  }

  @include screen-sm-and-below {
    &.manualcarousel-content-container {
      flex-direction: column;
      justify-content: flex-start;
      padding: $spacing-small;

      .image-container {
        width: initial;
        height: 50%;

        img {
          width: initial;
          height: 100%;
        }
      }

      .item-list {
        margin-top: 1rem;
        width: 100%;
        text-align: center;

        .name-and-title {
          justify-content: flex-start;
          height: initial;
        }

        .description-container {
          margin-top: 3rem;
          height: 4rem;
          align-items: center;

          .description { display: none; }
          .description-small { display: none; }
          .description-vsmall { display: block; }
          .pill-button { 
            margin-top: 3rem;
            font-size: 80%;
          }
        }
      }
    }
  }
}
