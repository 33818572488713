@import 'src/app/static/styles/main.scss';


.aboutpage-layout {
  
  .formwrapper-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 180px;
    gap: 35px;
  
      .category-1-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 500px;
        gap: 35px
      }
  
      .category-2-wrapper {
        display: flex;
        flex-direction: column;
        gap: 80px;
      }

      .about-text-container {
        width: 50%;
      }

      .greyback-container {
        width: 50%;
        height: 100%;
      }

      .header {
        text-align: left;
        font-size: 3rem;
        font-weight: bold;
        color: black;
      }
    }
  }