@import 'src/app/static/styles/main.scss';

.checkout-success-page {
    .title-order-id {
        margin-top: 14px;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: bold;
        color: colors(_blue);
    }

    .order-success-message {
        margin-top: 40px;
        margin-bottom: 80px;
        max-width: 500px;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.4;

        .message-callout {
            margin: 80px 0 20px;
            font-weight: bold;
            font-size: 2.1rem;
        }
    }
}