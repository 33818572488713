@import 'src/app/static/styles/main.scss';


.tag-accept-modal {
    .title {
        font-size: 2.2rem;
        line-height: 1.2;
        color: colors(_blue)
    }

    .sub-title {
        font-size: 1.8rem;
        line-height: 1.4;
        color: rgba(0, 4, 12, .5);
        text-align: center;
        white-space: pre-wrap;
    }

    .regular-text {
        margin: 40px 0 20px;
        font-size: 1.8rem;
        line-height: 1.4;
        color: rgba(0, 4, 12, .7);
        text-align: center;
    }

    .error-message {
        margin-top: 40px;
        font-size: 1.6rem;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
        text-align: left;
    }

    .success-message {
        margin-top: 40px;
        font-size: 1.6rem;
        font-weight: bold;
        color: green;
        white-space: pre-wrap;
        text-align: center;
    }

    .email-input {
        margin-top: 40px;
        padding: 12px 20px;
        width: 100%;
        background-color: rgba(0, 0, 4, .07);
        color: rgba(0, 4, 12, .8);
        font-size: 1.6rem;
        border-radius: 6px;
        white-space: pre-wrap;
    }

    .buttons-row {
        display: flex;
        justify-content: space-between;

        .block-button {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

}
