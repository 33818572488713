@import 'src/app/static/styles/main.scss';

$border-pad: 30px;
$label-right-pad: 16px;

.credit-application-parent {
    &.credit-application-parent {
        margin-top: 30px;
    }


    .page-title {
        color: colors(_black);
        font-size: font-sizes(h3);
        text-transform: uppercase;
        font-weight: 600;
    }

    .progress-bar-wrapper {
        width: 80%;
        margin: 60px auto;
    }

    .form-border {
        margin-top: 20px;
        padding: $border-pad $border-pad $border-pad 0;
        border: 1px solid rgba(0, 0, 0, .1);

        // ============ form row with flexbox
        .form-row {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
            overflow: hidden;
            

            .form-field {
                display: flex;
                align-items: center;
                padding: 10px $border-pad 10px 0;

                &.no-right-padding {
                    padding-right: 0;
                }
                
                label {
                    flex-shrink: 0;
                    margin-left: $border-pad;
                    margin-right: $label-right-pad;
                    color: #000;
                    font-size: font-sizes(inputLabel);

                    &.no-left-margin {
                        margin-left: 0;
                    }

                    .subtext {
                        font-size: 90%;
                        font-style: italic;
                    }
                }
            }

            .width-fixed {
                margin-left: $border-pad;
                margin-right: $label-right-pad;
                width: 180px;
            }
        }

        .separator-bar {
            margin: 60px 0px 60px $border-pad;
            width: calc(100% - (($border-pad) * 1));
            height: 3px;
            background-color: colors(light-grey);
        }
    }

    .section-title {
        margin-left: $border-pad;
        color: colors(_blue);
        text-transform: uppercase;
        font-size: font-sizes(h6);
        font-weight: bold;
        margin-bottom: 50px;

        &.page-title {
            margin: 0 auto;
            text-align: center;
            font-size: font-sizes(h5);
            font-weight: 600;
        }
    }

    .success-message {
        margin: 40px auto; 
        width: 50%;
        min-width: 400px;
    }

    .next-and-prev-buttons-container {
        display: flex;
        justify-content: space-between;
        margin: 60px 0;

        &.center {
            justify-content: center;
        }

        &.right {
            justify-content: flex-end;
        }
    }
}
