@import 'src/app/static/styles/main.scss';


.jesse-testing {
    padding: 30px;

    div {
        margin-top: 20px;
    }
}
