@import 'src/app/static/styles/main.scss';

.set-toggle-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.large {
        column-gap: 36px;
        row-gap: 24px;
    }

    &.small {
        column-gap: 12px;
        row-gap: 12px;
    }

    .toggle-button {
        position: relative;
        font-family: 'Open Sans';
        text-align: center;
        user-select: none;
        transition-duration: 200ms;

        &.large { // dark by default
            padding: 8px 48px 16px;
            border: 2px solid rgba(7, 13, 40, .7);
            border-radius: 4px;
            outline: none;
            font-size: 3.5rem;
            font-weight: 700;

            &.white.selected,
            &:not(.white):not(.selected) {
                background-color: rgba(7, 13, 40, 1);
                color: white;

                .upper-text {
                    color: white;
                }
            }

            &.white:not(.selected), 
            &:not(.white).selected {
                background-color: rgba(0, 0, 0, 0);
                color: #303a57;

                .upper-text {
                    color: #303a57;
                }
            }

            .upper-text { // dark by default
                margin-top: 4px;
                margin-bottom: 8px;
                background: transparent;
                font-size: 1.5rem;
                color: white;
            }
        }

        &.small { // dark by default
            padding: 12px 16px;
            border: 2px solid rgba(7, 13, 40, .7);
            border-radius: 4px;
            font-size: 1.5rem;
            font-weight: 600;

            &.white.selected,
            &:not(.white):not(.selected) {
                background-color: rgba(7, 13, 40, 1);
                color: white;

                .upper-text {
                    color: white;

                    &.small {
                        opacity: .65;
                    }
                }
            }

            &.white:not(.selected),
            &:not(.white).selected {
                background-color: rgba(0, 0, 0, 0);
                color: #303a57;

                .upper-text {
                    color: #303a57;
                    opacity: .65;
                }
            }

            .upper-text { // dark by default
                &.small.small {
                    display: none;
                    position: absolute;
                    top: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 0 8px;
                    height: 15px;
                    width: max-content;
                    font-size: 12px;
                    line-height: 1.2;
                    border-radius: 4px;
                    // color: #778;
                }
            }
        }

        &:hover {
            .upper-text.small.small.small.small {
                display: block;
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
}
