@import 'src/app/static/styles/main.scss';

.carousel-2-main {
  position: static;
  width: 1100px;
  height: 430px;
  padding: 15px 15px 15px 15px;
  margin: 0 auto;
  font-family: fonts-family(_fonts);
  background-color: white;

  @keyframes tonext {
    75% {
      left: 0;
    }

    95% {
      left: 100%;
    }

    98% {
      left: 100%;
    }

    99% {
      left: 0;
    }
  }

  @keyframes tostart {
    75% {
      left: 0;
    }

    95% {
      left: -300%;
    }

    98% {
      left: -300%;
    }

    99% {
      left: 0;
    }
  }

  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }

    97% {
      scroll-snap-align: none;
    }

    99% {
      scroll-snap-align: none;
    }

    100% {
      scroll-snap-align: center;
    }
  }

  *::-webkit-scrollbar {
    width: 0;
  }

  .carousel {
    text-align: center;
    position: relative;
    perspective: 100px;
    width: 507px;
    height: 310px;
    background-color: white;
  }

  .carousel__viewport {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    overflow-x: scroll;
    counter-reset: item;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    width: 102%;
    height: 400px;
  }

  .carousel__slide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    counter-increment: item;
  }

  .carousel__slide:before {
    content: counter(item);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -40%, 70px);
    color: #fff;
    font-size: 2em;
  }

  .carousel__snapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: center;
  }

  @media (hover: hover) {
    .carousel__snapper {
      animation-name: tonext, snap;
      animation-timing-function: ease;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    .carousel__slide:last-child .carousel__snapper {
      animation-name: tostart, snap;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    .carousel__snapper {
      animation-name: none;
    }
  }

  .carousel:hover .carousel__snapper,
  .carousel:focus-within .carousel__snapper {
    animation-name: none;
  }

  .carousel__navigation {
    padding-top: 65.9%;
    position: absolute;
    right: 0;
    bottom: -5;
    left: 0;
  }

  .carousel__navigation-list,
  .carousel__navigation-item {
    display: flex;
    justify-content: center;
  }

  .carousel__navigation-button {
    display: flex;
    width: 10px;
    height: 10px;
    background-color: rgba(141, 140, 140, 0.516);
    background-clip: content-box;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;

    &:hover {
      background-color: colors(_purple);
    }

    &:active {
      background-color: purple;
      color: purple;
    }
  }

  .imagetwo {
    width: 470px;
  }

  .imagethree {
    width: 485px;
  }

  .dots-padding {
    padding-left: 8px;
  }

  //===========================================================================

  .slider__nav {
    border-radius: 50%;
    z-index: 10;
    outline-offset: -6px;
    box-shadow: 0 0 0 0 #333, 0 0 0 0 rgba(51, 51, 51, 0);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .carousel__navigation-item:checked {
    background-color: purple;
    color: purple;
  }

  .carousel__navigation-item:checked:nth-of-type(1) ~ .slider__inner {
    left: 0%;
  }

  .carousel__navigation-button:checked:nth-of-type(2) ~ .slider__inner {
    left: -100%;
  }

  .carousel__navigation-button:checked:nth-of-type(3) ~ .slider__inner {
    left: -200%;
  }

  //===========================================================================

  .slider__nav:focus {
    background-color: purple;
  }

  .slider__nav:focus:nth-of-type(1) ~ .slider__inner {
    animation: none;
    left: 0;
  }

  .slider__nav:focus:nth-of-type(1) ~ .slider__inner {
    animation: none;
    left: -100%;
  }

  .slider__nav:focus:nth-of-type(1) ~ .slider__inner {
    animation: none;
    left: -200%;
  }
}
