// Min width for all contents on all pages
$page-max-width: screen-widths(xl);
$page-min-width: screen-widths(mobile);
$page-side-padding: 40px; // Minimum left & right padding for the whole page.

// Borders and shadows
$light-border: 1px solid rgba(0, 0, 0, 0.08);
$box-shadow: 2px 3px 6px 1px #a349c924;
$box-shadow-dark: 2px 3px 6px 1px #00000024;

// Header and navbar
$header-height: 110px; // The white header. // IMPORTANT: if this value is changed, then also change NAV_OPACITY_SCROLL_POS in Navbar.tsx
$navbar-height: 60px; // The blue nav bar
$navbar-background-solid: rgba(63, 117, 187, 1);
$navbar-background-gradient-translucent: linear-gradient(
  137deg,
  rgba(63, 117, 187, 0.8) 49%,
  rgba(72, 153, 198, 0.8) 57%
);
$navbar-background-gradient-opaque: linear-gradient(
  137deg,
  rgba(63, 117, 187, 1) 49%,
  rgba(72, 153, 198, 1) 57%
);

// Body section of all pages
$body-top-padding: $navbar-height + 40px;
$body-bottom-padding: 80px;

// Banner images: Primary style, used for vendor registration pages
// IMPORTANT: if this value is changed, then also change NAV_OPACITY_SCROLL_POS in Navbar.tsx 
$banner-image-height: 260px;
$banner-text-margin: -$banner-image-height / 2;

// - all that you need to change is the ratio number
$banner-1-size-ratio: .7;
$banner-1-base-height: 455; // needs to match actual height of doctor-pen.png.
$banner-1-base-width: 1920; // needs to match actual height of doctor-pen.png.
$banner-1-image-height: ($banner-1-base-height * $banner-1-size-ratio) + px;
$banner-1-image-width: ($banner-1-base-width * $banner-1-size-ratio) + px;
$banner-1-text-margin: (-$banner-1-base-height * $banner-1-size-ratio / 2) + px;
$banner-1-image-minwidth: ($banner-1-base-width * $banner-1-size-ratio) + px;


// Banner images: Alternate style, for /public/home.
// - all that you need to change is the ratio number
$banner-2-size-ratio: .7;
$banner-2-base-height: 675; // needs to match actual height of home-skel-products.png.
$banner-2-base-width: 1920; // needs to match actual height of home-skel-products.png.
$banner-2-image-height: ($banner-2-base-height * $banner-2-size-ratio) + px;
$banner-2-image-width: ($banner-2-base-width * $banner-2-size-ratio) + px;
$banner-2-text-margin: (-$banner-2-base-height * $banner-2-size-ratio / 2) + px;
$banner-2-image-minwidth: ($banner-2-base-width * $banner-2-size-ratio) + px;


// Banner images: Alternate style, for /public/vendor-profile.
// - all that you need to change is the ratio number
$banner-3-size-ratio: .7;
$banner-3-base-height: 862; // needs to match actual height of regen-banner-vendor.png.
$banner-3-base-width: 1920; // needs to match actual height of regen-banner-vendor.png.
$banner-3-image-height: ($banner-3-base-height * $banner-3-size-ratio) + px;
$banner-3-image-width: ($banner-3-base-width * $banner-3-size-ratio) + px;
$banner-3-text-margin: (-$banner-3-base-height * $banner-3-size-ratio / 2) + px;
$banner-3-image-minwidth: ($banner-3-base-width * $banner-3-size-ratio) + px;


// Global background color:
$page-background-color: #fff;


// Header search bar
$search-container-height: 46px;
$search-container-padding-vertical: 5px;
$search-container-padding-horizontal: 20px;
$dropdown-icon-size: 16px;
$header-icon-size: 32px;
$header-icon-size-search: 25px;