@import 'src/app/static/styles/main.scss';


.verification-code-container {
    margin: 150px auto;
    width: 50%;
    max-width: 800px;
    min-width: 400px;

    .title {
        text-align: center;
        text-transform: uppercase;
        font-size: 2.2rem;
        font-weight: font-weights(bold);
        color: colors(_blue);
    }

    .subtitle {
        margin-top: 80px;
        font-size: font-sizes(h3);
        color: colors(_black);
    }

    .description {
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 1.6rem;
        font-style: italic;
        line-height: 1.5;
        color: rgba(0, 4, 12, .7);
    }

    .form {
        margin-top: 40px;

        .header {
            margin-top: 20px;
            margin-bottom: 12px;
            font-size: 1.6rem;
            color: rgba(0, 4, 12, .6);
        }
    }

    .error-message {
        margin-top: 60px;
        margin-bottom: 4px;
        text-align: center;;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
      }
}