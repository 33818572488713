@import 'src/app/static/styles/main.scss';


.rep-dashboard-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;
  padding-bottom: 300px;
  margin-top: 160px;

  .search-span {
    display: flex;
    justify-content: flex-end;
  }

  .header {
    text-align: left;
    font-size: 1.5rem;
    font-weight: 700;
    color: black;

    &.small {
      font-size: 1.1rem;
    }

    &.blue {
      color: rgb(65, 118, 187);
    }
  }

  .top-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;
    width: 100%;
    margin-top: 30px;

    .top-row {
      display: flex;
      flex-direction: row;
      gap: 0.6%;
      width: 100%;

      .columns-top {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 15px 10px 15px;
        width: 19.2%;

        &.right-side {
          display: flex;
          flex-direction: row;
          gap: 40px;
        }

        .info-card {
          display: flex;
          flex-direction: column;
          gap: 22px;

          &.right-side {
            gap: 13px;
          }

          .info-span {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;

            .circle-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-weight: bold;
              font-size: 1.4rem;
              background-color: black;
              color: white;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }

            img { 
              width: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .middle-row {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 30px;
      gap: 0.6%;

      .container {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 15px 10px 15px;
        gap: 20px;

        &.map {
          width: 58.8%;
        }

        &.right-side {
          width: 19.2%;
          height: 280px;

          &.two {
            gap: 20px;
          }

          .leaderboards {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        .centering-map {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .placeholders {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          background-color: grey;
          color: white;

          &.heatmap {
            height: 500px;
          }

          &.circle-chart {
            width: 170px;
            height: 170px;
            border-radius: 50%;
          }
        }

        .leaderboard-container {
          display: flex;
          flex-direction: column;
          column-gap: 5px;
        }
      }
    }

    .box {
      display: flex;
      align-items: center;
      padding: 15px 10px 15px;
      font-size: 1.4rem;
      background-color: rgba(93, 93, 93, 0.092);
    }

    .bottom-container {
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 20px 10px 10px;
      gap: 15px;
      width: 58.8%;
      margin-top: 30px;
      padding-bottom: 20px;

      .text-span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          font-size: 1.3rem;
        }
      }
    }

  }
}