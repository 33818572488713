@import 'src/app/static/styles/main.scss';



.tag-doctor-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 120px;
    padding-bottom: 10px;

      .header {
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
          color: black;

          &.blue {
              color: rgb(65, 118, 187);
              padding-bottom: 30px;
          }

          &.small {
              font-size: 2rem;
          
              &.blue {
                color: rgb(65, 118, 187);
              }
          }
    }
  }

  .teal-display-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1%;

    .teal-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 17px;
      width: 33%;
      gap: 15px;
      border-radius: 8px;
      background-color: rgba(89, 190, 204, 255);
      color: white;

      .display-text {
        font-size: 1.6rem;
        font-weight: 600;
      }

      .display-number {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }

  .information-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 3px;
    margin-top: 50px;
    width: 100%;
    background-color: white;
    gap: 3.3%;
    padding: 40px 23px;

    .header {
      text-align: left;
      font-size: 2.4rem;
      font-weight: bold;
      color: black;
      padding-bottom: 20px;

      &.blue {
          color: rgb(65, 118, 187);
      }
    }

    .information-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 30%;

      .information-text {
        font-weight: bold;
        margin-top: 20px;        
      }

      .text-required-wrap {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 5px;
      }

      .span-padding {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .white-space {
        padding-bottom: 10px;
        color: white;
       }

      .state-zip-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .state-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 30%;
        }
        
        .zip-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 60%;
        }
      }
      
      .button-container {
        display: flex;
        align-items: flex-end;
        height: 450px;

        .button-inject {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          padding-left: 10px;
          padding-right: 20px;

          .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            background-color: black;
            border-radius: 50%;

            .plus {
              width: 12px;
              height: 12px;
              background-color: white;
              clip-path: polygon(
                0 39%, 
                40% 39%, 
                40% 0, 
                61% 0, 
                61% 39%, 
                100% 39%, 
                99% 59%, 
                61% 59%, 
                61% 100%, 
                40% 100%, 
                40% 59%, 0 59%
                );
            }
          }
        }
      }
    }
  }

}