@import 'src/app/static/styles/main.scss';


.sales-report-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 120px;
    padding-bottom: 40px;

      .header {
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
          color: black;

          &.blue {
              color: rgb(65, 118, 187);
          }

          &.small {
              font-size: 2rem;
          
              &.blue {
                color: rgb(65, 118, 187);
              }
          }
      }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;
    width: 100%;
    background-color: white;
    padding: 40px 23px;

    .header {
      text-align: left;
      font-size: 2.2rem;
      font-weight: bold;
      color: black;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 30px;
      
    }

    .select-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 25px;
      margin-top: 45px;
      padding-bottom: 45px;

      .column {
        display: flex;
        flex-direction: column;
        width: 24%;
        gap: 15px;

        span {
          font-weight: 700;
        }

        &.date-range {
          width: 24%;
        }

        &.button {
          width: 11%;
        }
      }
    }

    .volumetext-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5%;
      width: 93%;

      .dot {
        border-radius: 50%;
        background-color: rgba(89, 190, 204, 255);
        width: 10px;
        height: 10px;
      }

      span {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    .graph-chart {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      margin-bottom: 40px;

      .main-container {
      display: flex;
      flex-direction: row;

      .left-numbers {
        display: flex;
        flex-direction: column;
        gap: 30px;

        span {
          font-weight: bold;
        }

        .close-right {
          display: flex;
          justify-content: flex-end;
        }
      }

      .chart-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 525px;
        gap: 3.5%;

        .chart-itself {
          display: flex;
          justify-content: flex-start;
          width: 93%;
          height: 100%;
          border-bottom: 2px solid black;
          border-left: 2px solid black;;
        }

        .bottom-numbers {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          gap: 20px;

          span {
            font-size: 1.6rem;
            font-weight: bold;
          }
    
        }
      }
      }
    }

    .record-text {
      margin-top: 55px;
    }

    .table-container {
      width: 100%;
      padding-bottom: 90px;
    }

    .top-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin-top: 50px;

      &.bottom-span {
        margin-top: 0px;
      }

   .left-box {
     display: flex;
     flex-direction: row;
     align-items: baseline;
     column-gap: 20px;

        .dropdown {
          width: 85px;
          height: 40px;
        }
      }
      
        .search-container {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: baseline;
          column-gap: 25px;

          span {
            font-weight: bold;
          }
        }
    }
  }
}