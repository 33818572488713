.my-account-navbutton {
  padding: 12px 3px 12px 13px;
  width: 147px;
  font-size: font-sizes(button);
  color: white;
  background-color: #457ebe;
  cursor: pointer;
}

.small-arrow1 {
  position: absolute;
  right: 3px;
  top: -2px;
  margin-top: -6px;
  // background-color: black;
  width: 20px;
  height: 20px;
  background-color: black;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 30px solid black;
  // border-width: 6px 0 6px 6px;
  // border-style: solid;
  // border-color: transparent #fff;
  // border-color: black;
}

.my-account-dropdown {
  width: 240px;
  margin: 15px 0px 0px 0px;
  padding: 10px 13px 12px 13px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 12px 3px rgba(10, 10, 10, 0.182);
}

.my-account-items {
  font-size: 1.5rem;
  font-weight: bold;
  color: #070d27a3;
  margin-top: 7px;
  padding: 7px 10px 7px 10px;
  background-color: white;
  cursor: pointer;

  &:hover {
    background-color: rgba(128, 128, 128, 0.12);
  }
}
