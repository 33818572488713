@import 'src/app/static/styles/main.scss';
//-----------------------------

$button-height: 48px;

.block-button {
  padding: 0 80px;
  height: $button-height;
  min-width: 50px;
  border-radius: 4px;
  text-align: center;
  font-family: font-families(base);
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s;
  cursor: pointer;

  &:not(.no-hover):hover {
    box-shadow: $box-shadow;
  }

  &:active {
    box-shadow: none;
    opacity: 80%;
  }

  /*
   * Buttons that contain icons
   */
  .icon-then-text {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: ($button-height * 0.7);
      margin-right: 1rem;
      width: 100%; //added to prevent overflow of click event beyond button border
    }
  }

  /*
	* ==========================  Alt styles
	*/

  &.desktop-header-buttons {
    padding: 0 10px;
    min-width: 10px;

    &.white {
      color: black;
      background-color: white;
      border: 2px solid black;
    }

    &.bodymap {
      display: flex;
      flex-direction: row;
      font-size: 1.5rem;
      padding: 10px 20px 10px 20px;
      min-width: 10px;
      height: 100%;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 40px;
      color: black;
      //  background: #457ebe;
      //  background: linear-gradient(140deg, #457dbe7a 33%, #5abdcc6e 70%);
      background: #457ebe;
      background: linear-gradient(
        40deg,
        #e83effde 8%,
        #b027ff3f 25%,
        #b027ff21 75%
      );
      // background-color: rgba(255, 0, 255, 0.623);
      //  background-color: rgba(253, 147, 61, 0.322);
      box-shadow: rgb(211, 99, 255) 1px 1px 4px 1px;
      //  box-shadow: rgb(255, 148, 99) 1px 1px 4px 1px;

      &:not(.no-hover, :disabled):hover {
        background: #457ebe;
        background: linear-gradient(
          40deg,
          #e83effde 8%,
          #b027ff63 25%,
          #b027ff46 75%
        );
        //   color: #fff;
        transition: 1.3s;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  &.purple {
    color: #fff;
    background-color: colors(_purple);
    border: 2px solid colors(_purple);

    &:not(.no-hover, :disabled):hover {
      background-color: #fff;
      color: colors(_purple);
    }

    &:active {
      box-shadow: none;
    }
  }

  &.dark {
    color: #fff;
    background-color: colors(very-dark-blue);
    border: 1px solid colors(very-dark-blue);

    &:not(.no-hover, :disabled):hover {
      color: colors(very-dark-blue);
      background-color: #fff;
      box-shadow: $box-shadow-dark;
    }
    &:active {
      box-shadow: none;
    }
  }

  &.ghost-purple {
    background-color: transparent;
    color: colors(_purple);
    border: 2px solid colors(_purple);
    box-shadow: none;

    &:not(.no-hover, :disabled):hover {
      color: #fff;
      background-color: colors(_purple);
      border: 1px solid colors(_purple);
    }
    &:active {
      box-shadow: none;
    }
  }

  &.ghost-dark {
    background-color: transparent;
    color: colors(very-dark-blue);
    border: 2px solid colors(very-dark-blue);
    box-shadow: none;
    font-weight: 600;

    &:not(.no-hover, :disabled):hover {
      color: #fff;
      background-color: colors(very-dark-blue);
      border: 2px solid colors(very-dark-blue);

      svg {
        filter: invert(100%);
      }
    }

    &:active {
      box-shadow: none;
    }
  }

  &.ghost-mid {
    background-color: transparent;
    color: rgba(32, 4, 32, .5);
    border: 2px solid rgba(32, 4, 32, .5);
    box-shadow: none;
    font-weight: 600;

    &:not(.no-hover, :disabled):hover {
      // color: #fff;
      background-color: rgba(32, 4, 32, .1);
      border: 2px solid rgba(32, 4, 32, .7);

      svg {
        filter: invert(100%);
      }
    }

    &:active {
      box-shadow: none;
    }
  }

  &.no-text-transform {
    text-transform: initial;
  }

  &.no-pad {
    padding: 0;
  }

  &.less-pad {
    padding: 0 24px;
  }

  &.little-pad {
    padding: 0 6px;
  }

  &.little {
    padding: 0;
    min-width: 32px;
    height: 32px;
  }

  &.mid-size {
    padding: 0 48px;
    height: $button-height - 8px;
    font-size: 1.6rem;
  }

  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: all;

    &:hover {
      box-shadow: none;
    }
  }
}
