@import 'src/app/static/styles/main.scss';


.textarea-field {
    max-width: 100%;
    min-width: 100%;
    padding-left: 15px;
    padding-top: 15px;
  //  background: rgba(0,0,0,0.1);
    border: none;
    border-radius: 5px;
    font-family: font-families(base);
// added
    background-color: colors(light-grey);
    color: colors(_black);


    &:focus {
        outline: none;
    }

    // Because placeholders in <textarea>'s are weird
    &.placeholder {
        color: rgb(117, 117, 117);
        font-weight: 400;
        font-size: 2rem;
    }
}