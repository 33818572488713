@import 'src/app/static/styles/main.scss';


.login-form-wrapper {
    margin: 150px auto;
    width: 50%;
    max-width: 400px;
    min-width: 200px;

    .title {
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase;
        font-size: font-sizes(h2);
        font-weight: font-weights(bold);
        color: colors(_blue);

        &.subtitle {
            margin-top: 80px;
            font-size: font-sizes(h3);
            color: colors(_black);
        }
    }

    // TODO: cleanup * selector
    .form > * {
        margin-bottom: 20px;
    }

    .registration-message {
        margin-bottom: 64px;
        text-align: center;
        font-size: 2.4rem;
        line-height: 1.4;
        font-weight: bold;
        color: rgba(139, 69, 189);
    }

    .links-wrapper {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: center;

        .link {
            font-size: font-sizes(small);
            font-weight: normal;
            color: colors(_black);

            &:hover,
            &:focus {
                color: colors(_black)
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .error-message {
        margin-top: 4px;
        margin-bottom: 4px;
        text-align: center;;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
      }
}