@import 'src/app/static/styles/main.scss';

.product-list-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .header {
        text-align: left;
        font-size: 3rem;
        font-weight: bold;
        color: black;

        &.hide {
            display: none;
        }
    }

    .product-category-list {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;

        &.hide {
            display: none;
        }

        .product-category-description {
            padding-right: 48px;
            width: 70%;
            font-size: 1em;
            line-height: 150%;
        }

        .pill-button.non-floating-button {
            display: flex;
        }

        .pill-button.floating-button {
            display: none;
        }

        > a {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    .product-display-grid {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
        margin-top: 120px;
        margin-bottom: 160px;

        &.condense {
            margin-top: 8px;
            margin-bottom: 48px;
        }

        .product-card-link {
            width: calc(50% - 40px);
        }
    }
}

@include screen-lg-and-below {
    .product-list-wrapper {

        .product-card-link.product-card-link.product-card-link {
            width: 90%;
        }
    }
}

@include screen-md-and-below {
    .product-list-wrapper {
        .product-category-list {
            .product-category-description {
                width: 100%;
                padding-right: 0;
            }

            .pill-button.non-floating-button.non-floating-button.non-floating-button {
                display: none;
            }
    
            .pill-button.floating-button.floating-button.floating-button {
                display: flex;
            }

            
        }
    }
}