@import 'src/app/static/styles/main.scss';

.generic-modal.info-text-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: .2;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
  
    &:hover,
    *:focus {
      opacity: .3;
    }
  }

  .modal-contents {
    height: 100%;
    width: 100%;
  }

  .bg-shade {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    z-index: 2;
    background: white;
    top: calc(50vh + 50px);
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 50px;
    border-radius: 5px;
    max-height: calc(90vh - 100px);
    max-width: 90vw;
    overflow: scroll;

    .title {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2.8rem;
      font-weight: 600;
      color: colors(dark-blue);
    }

    .subtitle {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
      font-style: italic;
      white-space: pre-line;
    }

    .body-text {
      font-size: 18px;
      line-height: 1.4;
      white-space: pre-wrap;
    }
  }
}
