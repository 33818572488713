@import 'src/app/static/styles/main.scss';



.grid-container {
  font-weight: 700;
  display: grid;
  grid-template-columns: 0;
  grid-auto-rows: 50px;
  width: 100%;
  height: auto;

  &.checkout-container {
    grid-template-columns: 627px 119px 119px 182px 205px;
    gap: 8.5px;
  }

  &.procedures-container {
    grid-template-columns: 270px 155px 217px 187px 430px;
    gap: 15px 0.5%;
  }

  &.vendordash-container {
   // grid-template-columns: 310px 155px 217px 187px 430px;
    grid-template-columns: 25% 10% 25% 20% 20%;
    gap: 15px 0.5%;
  }

  &.tags-container {
   // grid-template-columns: 134px 183px 173px 350px 372px;
    grid-template-columns: 12% 17% 17% 26% 26%;
    gap: 15px 0.5%;
  }

  &.leaderboard-container {
    grid-template-columns: 160px 355px 323px 378px;
    gap: 15px 0.5%;
  }

  &.salesreport-container {
    grid-template-columns: 14% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8%;
    gap: 15px 0.5%;
  }

  .checkout-header-col1 {
    text-transform: uppercase;
    display: flex;
    padding-left: 30px;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .checkout-header-cols {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .procedures-header-cols {
    text-transform: uppercase;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .vendordash-header-cols {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .tags-header-cols {
    text-transform: uppercase;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .salesreport-header-cols {
    text-transform: uppercase;
    font-size: 1.4rem;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .leaderboard-header-cols {
    text-transform: uppercase;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #303a57;
    color: white;
  }

  .checkout-items-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
  }

  .procedures-items-fill {
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #f0f0f0;
  }

  .vendordash-items-fill {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    color:  #4176BB;
  }

  .tags-items-fill {
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #f0f0f0;
  }

  .leaderboard-items-fill {
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: #f0f0f0;
  }

  .paging-style {
    display: flex;
    width: 100%;
  }

  .paging-block {

  }
}