@import 'src/app/static/styles/main.scss';



.consumer-dashboard-layout {
  padding-top: 200px;

  
  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;

    &.blue {
      margin-top: 60px;
      color: rgb(65, 118, 187);
    }

    &.orders {
      padding-bottom: 30px; 
    }

    &.products {
      padding-top: 90px;
    }
  }

  .recent-orders-wrapper {
    width: 100%;
    margin-top: 5px;
  }

  .products-wrapper {
    margin-top: 20px;

    .products-grid {
      margin-top: 58px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 20px;
      column-gap: 70px;
    }
  
    .grey-boxes {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 50px;
      background-color: rgba(224, 224, 224, 0.581);
    }
  }

  .image1 {
    margin-top: 70px;
    margin-left: 70px;
    height: 400px;
    width: 900px;
  }

  .events-wrapper {
    justify-content: center;
    align-items: center;
    margin-top: 80px;

    .events-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .event {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000, $alpha: 0.1);

        .bg {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
        }

        &.main-event {
          aspect-ratio: 1/1;
        }

        p {
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-weight: font-weights(semi-bold);
        }
      }

      .sub-events {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 10px;
      }
    }
  }
    
  .expansion-container {
    display: flex;
    width: 100%;
    margin-top: 70px;
    
    details {
      display: flex;
      user-select: none;
      width: 100%;

      .expansion-box {
        display: flex;
        flex-direction: column;
        gap: 50px;
        border-radius: 5px;
        width: 100%;
        margin-top: 30px;
        padding: 0px 0px 10px;
        font-weight: 600;
        background-color: rgba(224, 224, 224, 0.58);

        
    .salesnumbers-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

        .section {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          row-gap: 30px;

          &.chart {
            width: 35%;
            align-items: flex-end;
          }
        }

        .data {
          font-size: 3.5rem;
          font-weight: bold;
          letter-spacing: 3px;
        }

        .title {
          font-size: 2.2rem;
          font-weight: bold;
          letter-spacing: 1.2px;
        }

        .button {
          margin-top: 15px;
        }
      
    
  }
      }
    }
    
    details>summary span.arrow {
      clip-path: polygon(37% 49%, 100% 100%, 100% 0);
      background-color: black;
      width: 17px;
      height: 17px;
      transition: all 0.3s;
      margin-left: 20px;
      margin-top: 3px;
    }
    
    details[open] summary span.arrow {
      transform: rotate(-90deg);
      transition: all 0.3s;
    }
    
    summary {
      display: flex;
    //  justify-content: center;
      width: 100%;
      font-size: 2.4rem;
      font-weight: bold;
      color: rgb(65, 118, 187);
      cursor: pointer;
    }
    
    summary::-webkit-details-marker {
      display: none;
    }
  }
}