@import 'src/app/static/styles/main.scss';


.tag-tree-modal.tag-tree-modal {
    .modal-main {
        position: relative;
        width: 90%;
        min-width: 500px; 
        max-width: 800px;
        min-height: 400px;

        .title {
            font-size: 2.4rem;
            color: rgba(0, 4, 12, .75);
        }

        .sub-title {
            font-size: 1.8rem;
            font-style: italic;
            color: rgba(0, 4, 12, .5);
            text-align: center;
        }

        .tag-tree-container {
            margin-top: 40px;
            width: 100%;
            font-size: 1.6rem;

            .preload-spinner {
                margin: 40px 0 40px 50%;
                transform: translateX(-50%);
                height: 60px;
                width: 60px;
                opacity: .7;
            }

            .indent {
                margin-left: 24px;
                border-left: 1px solid transparent;

                &[data-level="x"] {
                    margin-left: 0;
                    border-left-color: #bcd;
                }

                .rep-name-email {
                    position: relative;
                    height: 4rem;
                    width: 100%;
                    border-radius: 4px;
                    user-select: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    overflow: hidden;

                    .no-hover-show {
                        position: absolute;
                        padding-left: 12px;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                        background-color: #def8;
                        font-weight: 600;
                        transition-duration: 200ms;
                        user-select: all;
                        cursor: default;
                        display: flex;
                        align-items: center;
                    }
                    .on-hover-show {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        border-radius: 4px;
                        display: flex;
                        align-items: stretch;
                        transition-duration: 200ms;
                        opacity: 0;

                        .user-id-slot {
                            padding: 0 12px;
                            // height: 90%;
                            border-radius: 6px;
                            background-color: #fdd;
                            font-weight: 600;
                            color: #445;
                            user-select: all;
                            cursor: default;
                            display: flex;
                            align-items: center;
                        }

                        .email-slot {
                            padding: 0 12px;
                            // height: 90%;
                            border-radius: 6px;
                            background-color: #eee;
                            font-weight: 400;
                            color: #445;
                            user-select: all;
                            cursor: default;
                            display: flex;
                            align-items: center;
                        }
                    }

                    &:hover {
                        .on-hover-show {
                            // transform: translateX(-100%);
                            opacity: .8;
                        }
                    }
                }

                .inline-error {
                    color: #f34;
                    font-weight: 600;
                }
            }

            .indent:hover {
                border-left-color: #bcd;
                > .rep-name-email > .no-hover-show {
                    background-color: #deff;
                }
            }
        }

        .has-missing-items-message {
            margin: 40px 0 20px 0;
            padding: 20px;
            width: 100%;
            background-color: rgba(0, 0, 0, .15);
            border-radius: 6px;
            overflow: hidden;
            text-align: left;
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: 600;
            // color: rgba(0, 4, 12, .8);
            color: #f34;
        }
        
        .buttons-row {
            display: flex;
            justify-content: space-around;
            width: 100%;

            .block-button {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        .error-message {
            margin-top: 40px;
            font-size: 1.6rem;
            font-weight: bold;
            color: red;
            white-space: pre-wrap;
            text-align: left;
        }

        .success-message {
            margin-top: 40px;
            font-size: 1.6rem;
            font-weight: bold;
            color: green;
            white-space: pre-wrap;
            text-align: center;
        }

        .email-input {
            margin-top: 40px;
            padding: 12px 20px;
            width: 100%;
            background-color: rgba(0, 0, 4, .07);
            color: rgba(0, 4, 12, .8);
            font-size: 1.6rem;
            border-radius: 6px;
            white-space: pre-wrap;
        }
    }
}
