@import 'src/app/static/styles/main.scss';


.header-banner-container-outer {
  .banner-container {
    width: 100%;
    overflow: hidden;

    .banner-image-container-outer {
      position: relative;

      .banner-image {
        height: auto;
        width: 100vw;
      }

      .banner-image-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
    }  

    .banner-right-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: translateY(-40%);
      width: 100%;

      .banner-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 45%;
        color: white;
        font-size: 3.6rem;
        font-weight: 700;
      }
      
      .banner-image-secondary-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 45%; 
        
        img.banner-image-secondary {
          object-fit: contain;
        }
      }

      .image-text {
        height: 280px;
        display: flex;
        align-items: center;
      }

      .home-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        max-width: 500px;
        row-gap: 6px;
        
      .home-line {
        font-size: 3.3rem;
        line-height: 120%;
        font-weight: lighter;
      }

      .home-line-two {
        letter-spacing: 7px;
        line-height: 45px;
      }
    }

      .vendor-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        row-gap: 60px;

        .vendor-name {
          color: black;
        }

        .vendor-logo {
          // max-width: 900px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }


    
    /**
    * Styles for different types of banners
    */
    // ======================================================================
    &.btype-default {
      height: $banner-1-image-height;
      .banner-image-container-outer {
        height: $banner-1-image-height;
        .banner-image-container {
          // height: $banner-1-image-height;
          .banner-image {
            min-width: $banner-1-image-minwidth;
          }
        }
      }
      .banner-right-container {
        margin-top: $banner-1-text-margin;
        .banner-title {
          justify-content: flex-start;
        }
      }
    }

    &.btype-home {
      height: $banner-2-image-height;
      .banner-image-container-outer {
        height: $banner-2-image-height;
        .banner-image-container {
          height: $banner-2-image-height;
          .banner-image {
            min-width: $banner-2-image-minwidth;
          }
        }
      }
      .banner-right-container {
        margin-top: $banner-2-text-margin;
        .banner-title {
          justify-content: center;
        }
      }
    }

    &.btype-vendorprofile {
      height: $banner-3-image-height;
      .banner-image-container-outer {
        height: $banner-3-image-height;
        .banner-image-container {
          height: $banner-3-image-height;
          .banner-image {
            min-width: $banner-3-image-minwidth;
          }
        }
      }
      .banner-right-container {
        margin-top: $banner-3-text-margin;
        .banner-title {
          justify-content: center;
        }
      }
    }
    // ======================================================================
    
    /** 
    * Responsive styling
    */
    @include screen-lg-and-below {
      // Special behavior for this body container because it doesn't respond the same way when inside
      // of .banner-container 
      .body-container-no-vert-pad.banner-right-container {
        justify-content: center;

        // Widen banner title container.
        .banner-title {
          width: 100%;
        }

        // Hide secondary inner banner image if there is one.
        .banner-image-secondary-container {
          display: none;
        }

        .vendor-content-wrapper {
          align-items: center;
        }
      }
      
    }
  }
}

