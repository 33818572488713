.product-card {
    display: flex;
    justify-content: space-between;
    font-family: 'Open Sans';
    width: 100%;
    padding: 32px;
    border-radius: 16px;
    transition: background-color 350ms;


    &:hover,
    &:focus {
        background-color: rgba(4,0,12,.05);
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40%;
        margin-right: 7%;

        img {
            width: 100%;
            max-width: 230px;
        }
    }

    .item-list {
        display: grid;
        row-gap: 15px;
        width: 53%;
      
        .item-li-group {
            list-style: none;
            color: black;
            font-size: 1.5rem;
            font-weight: 700;

            &.productname {
                font-size: 2.3rem;
                font-weight: bold;
                //  height: 30px;
            }

            &.brandname {
                padding-top: 15px;
                font-weight: 600;
            }

            &.vendorname {
                font-weight: 600;
            }

            &.productdescription {
                font-weight: 100;
                padding-top: 15px;
                line-height: 25px;
            }
        }
    }
}
