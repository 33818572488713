@import 'src/app/static/styles/main.scss';

.div-contain-page-numbers {
  text-align: center;
  display: flex;
  //  align-items: baseline;
  justify-content: space-evenly;
  width: 490px;

  .arrow-box {
    display: flex;
    flex: wrap;
    width: 33px;
    height: 33px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.088);
    cursor: pointer;

    &.arrow-prev {
      margin-top: 4px;
      margin-left: 5px;
      width: 1.1em;
      height: 1.1em;
      background-color: black;
      clip-path: polygon(
        60% 13%,
        62% 19%,
        37% 52%,
        68% 82%,
        65% 87%,
        60% 90%,
        54% 89%,
        21% 53%,
        50% 14%,
        54% 11%
      );
    }

    &.arrow-next {
      margin-top: 4px;
      margin-left: 6px;
      width: 1.1em;
      height: 1.1em;
      background-color: black;
      clip-path: polygon(
        29% 10%,
        36% 10%,
        71% 50%,
        40% 88%,
        34% 91%,
        27% 88%,
        27% 81%,
        54% 50%,
        26% 21%,
        25% 14%
      );
    }

    &:hover {
      background-color: colors(_purple);

      .arrow-prev {
        background-color: white;
      }

      .arrow-next {
        background-color: white;
      }
    }
  }

  .numbers {
    font-family: 'Open Sans';
    font-weight: 600;
    padding-top: 6px;
    width: 30px;
    height: 30px;
    background-color: white;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: colors(_purple);
      border-radius: 3px;
      color: white;
    }

    &:active {
      background-color: colors(_purple);
      border-radius: 3px;
      color: white;
    }

    &:focus {
      background-color: colors(_purple);
      border-radius: 3px;
      color: white;
    }

    &.active {
      background-color: colors(_purple);
      border-radius: 3px;
      color: white;
    }
  }
}
