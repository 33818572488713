@import 'src/app/static/styles/main.scss';


.cart-check-out-page {
    .section-header {
        margin: 40px 0 16px;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: bold;
        color: colors(_blue);

        &.first {
            margin-top: -40px;
            color: rgba(0, 0, 8, .8);
            font-size: 1.8rem;
        }

        &.second {
            margin: 0 0 24px;
        }
    }
    .section-subheader {
        text-transform: uppercase;
        padding-bottom: 20px;
        font-size: font-sizes(small);
        font-weight: font-weights(bold);
    }

    .address-selection {
        margin-bottom: 50px;

        .select-address {
            padding: 10px;
            border: $light-border;
            width: 300px;
            font-weight: font-weights(semi-bold);

            .address-header {
                margin: 16px 0 8px 12px;
                font-size: 1.4rem;
                font-weight: bold;
            }

            address {
                margin-left: 12px;
                font-size: 1.4rem;
                line-height: 1.2;
                opacity: .8;
            }
        }
    }


    .order-summary-section {
        @extend .order-summary-table;
        margin-bottom: 50px;
        padding-bottom: 30px;
        border-bottom: 2px solid colors(_black, 0.08);
        font-size: 18px;
        overflow-y: scroll;

        .notes {
            .order-notes {
                margin-top: 10px;
                padding: 8px 16px 16px;
                width: 100%;
                height: 90px;
                border: 2px solid colors(_black, 0.08);
                border-radius: 5px;
                outline: none;
                background-color: #fff;
                font-family: font-families(base);
                font-size: 14px;
                line-height: font-sizes(mini) * 1.5;
                color: rgba(0, 0, 8, .8);

                &.taller {
                    height: 200px;
                }
            }

            .note-warning-container {
                position: relative;
                width: 100%;
                height: 0rem;

                .note-warning {
                    position: absolute;
                    top: calc((-1.4rem * 1.2) - 8px);
                    right: 8px;
                    font-size: 1.4rem;
                    line-height: 1.2;
                    color: #f34;
                    
                    button.hide-note-warning {
                        position: inline-block;
                        margin-left: 8px;
                        padding: 4px;
                        border-radius: 8px;
                        color: rgba(0, 0, 0, .6);
                        font-weight: bold;
                        transition-duration: 200ms;

                        &:hover {
                            background-color: rgba(0, 4, 8, .1);
                        }
                    }
                }
            }
        }

        .cart-cost {
            display: flex;
            justify-content: flex-end;
            margin: 40px 0;
    
            .cost-grid {
                width: 450px;
                display: grid;
                grid-template-columns: 1fr 2fr;
                gap: 10px;
                
                .coupon-label {
                    padding: 8px;
                    font-size: font-sizes(small);
                    font-weight: font-weights(bold);
                }
    
                .cost-summary {
                    .coupon-input {
                        position: relative;
                        margin-bottom: 24px;

                        .checking-coupon-spinner-in-input {
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            height: 24px;
                            width: 24px;
                        }
                    }

                    .price-row {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 8px;
                        font-size: font-sizes(small);

                        &.bold {
                            font-weight: bold;
                        }

                        .price-row-title {
                            text-align: left;
                        }

                        .price-row-value {
                            text-align: right;
                            
                        }
                    }

                    .price-row-separator {
                        width: 100%;
                        height: 2px;
                        margin: 8px auto;
                        background-color: rgba(0,0,0,.1);
                    }
                }
            }
        }
    }

    .payment-section {
        .section-header {
            margin-bottom: 40px;
        }

        .payment-option-selection {
            margin: 0 auto;
            width: 100%;
            max-width: 800px;
            min-width: 450px;
            
            .saved-payment-options {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 60px auto 0;
                padding: 60px 40px;
                width: 100%;
                min-width: 400px;
                max-width: 540px;
                border-radius: 8px;
                background: colors(_black, 0.08);
                text-align: left;
    
                .title {
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-weight: font-weights(bold);
                    text-align: center;
                }
    
                .subtitle {
                    margin: 50px 0 12px;
                    font-size: 1.6rem;
                    color: #181818;
                    text-align: center;
                }

                .payment-account-details {
                    margin-top: 12px;
                    font-size: 1.2rem;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }

        .alt-option {
            margin-top: 10px;
            text-align: center;
            font-size: font-sizes(mini);
            font-weight: font-weights(bold);
        }
    }


    .submit-section {
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }



    /**
     * Braintree
     */
    .credit-card-fields {

        .field-container {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &:not(:first-child) {
                margin-top: 8px;
            }

            // Checkmark/X icons wrappers for valid/invalid inputs.
            .bt-status {
                position: absolute;
                bottom: 5px;
                right: 5px;
                height: 24px;
                width: 24px;
                color: #ddd;

                &.valid {
                    display: none;
                }

                &.invalid {
                    color: #aaa;
                }
            }

            // This obj is mananged totally by us (as opposed to Braintree).
            .field-label {
                line-height: 1.5;
                font-size: 16px;
                color: #445;
                font-weight: 600;
            }

            // This is the wrapper around the hosted <iframe>, for each Braintree payment field, which 
            // will take of 100% of this container's size.
            // - Styles for any elements inside of this container can only be modified through the JS code.
            // - Some styles on those elements, some as background-color and height, can't be modified
            //   there, so apply those styles to the wrapper here instead.
            .field-input {
                margin-top: 4px;
                padding: 8px;
                width: 100%;
                height: 36px;
                border: 1px solid #aaa;
                border-radius: 5px;
                background-color: #fff0f0;

                &.braintree-hosted-fields-valid {
                    background-color: #def;
                    border: 1px solid #88f;

                    .bt-status.valid {
                        display: block;
                        color: #009922aa;
                    }
                    .bt-status.invalid {
                        display: none;
                    }
                }
                &.braintree-hosted-fields-invalid {
                    background-color: #fdd;
                    border: 1px solid #f88;

                    .bt-status.valid {
                        display: none;
                    }
                    .bt-status.invalid {
                        display: block;
                        color: #992244aa;
                    }
                }
            }

        }
    }
}


