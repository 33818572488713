@import 'src/app/static/styles/main.scss';


.vendor-register-page-layout {
    &.p4 { margin-top: 80px; }
    &.p5 { margin-top: 110px; }
    &.p6 { margin-top: 140px; }

  .progress-bar-placement {
    margin: 0 auto -80px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    padding: 45px 0px 80px;
      &.p3 {
        padding: 120px 0px 140px;
      }
  }

  .top-paragraph {
    padding-top: 110px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
      &.p5 {
        padding-top: 30px;
      }
  }

  .registration-message {
    margin-bottom: 64px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: bold;
    color: rgba(139, 69, 189);
  }

.form-layout {
  max-width: 720px;
}

  .paragraph-spacing {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .bottom-paragraph {
    margin-top: 110px;
    padding-bottom: 50px;
  }

  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
      &.p4 {
        margin-top: 90px;
        margin-bottom: 70px;
        color: black;
      }
      &.p5 {
        padding: 60px 0px 43px;
        color: black;
      }
      &.p6 {
        margin-top: 120px;
        margin-right: 800px;
        margin-bottom: -10px;
        color: black;
      }
  }

  .header2 {
    text-align: left;
    margin-bottom: 50px;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
      &.p4 {
        padding: 40px 0px 12px 0px;
        margin-bottom: 0px;
      }
      &.p5 {
        padding: 40px 0px 12px 0px;
        margin-bottom: 0px;
      }
  }

  .header3 {
    text-align: center;
    margin-top: -50px;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
      &.p4 {
        text-align: left;
        padding: 0px 0px 15px 15px;
        font-size: 2.4rem;
        margin-top: 0px;
      }
  }

  .img-upload-row {
    margin-top: 35px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 32px;
  }

  .grid-relay2 {
    display: flex;
    margin-top: 55px;
    gap: 105px;
      &.second {
        margin-top: 30px;
      }
  }

  .vendor-select-position {
    padding: 50px 0px 50px;
    width: 300px;
  }

  .form-wrapper-top {
      &.p1 {margin-bottom: 50px;}
      &.p2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 25px;
        margin-top: 45px;  //  SPACING HERE
      }
  }

  .form-wrapper-uppermid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
      &.p2 {    
        display: flex;
        flex-direction: column;
        padding: 25px 0px 25px;
      }
      &.p4 { 
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &.p5 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px 0px 40px;
      }

      .upload-product-greyback-container {
        width: 230px;
        
        .product-name {
          margin-top: 16px;
          width: 100%;
          color: rgba(0, 0, 16, .8);
          font-size: 2rem;
          text-align: center;
          font-weight: bold;
        }

        .add-product-call-to-action {
          margin-top: 16px;
          width: 100%;
          color: rgb(65, 118, 187);
          font-size: 2.4rem;
          text-align: center;
          font-weight: bold;
        }
      }

      .vendor-product-uploads-container {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
      }
  }

  .form-wrapper-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
      &.p1 { padding: 25px 0px 25px }
      &.p2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &.p5 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &.inv { 
        padding-left: 130px;
        margin-top: 50px; 
      }
  }
  
  .form-wrapper-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
      &.p2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
        padding: 25px 0px 40px;
      }
  }

  .greback-text-underscore {
    text-decoration: underline;
    padding-bottom: 7px;
    cursor: pointer;
  }

  .upload-content-icon {
    width: 110px;
    padding-bottom: 20px;
  }

  .events-wrapper {
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    width: 100%;

    .events-gallery {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        .event {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .bg {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 0;
                background: rgba($color: #000, $alpha: 0.1);
            }

            &.main-event {
                aspect-ratio: 1/1;
            }

            p {
                text-transform: uppercase;
                text-align: center;
                color: #fff;
                font-weight: font-weights(semi-bold);
                z-index: 1;
            }
        }

        .sub-events {
            display: grid;
            grid-template-rows: 1fr 1fr;
            gap: 10px;
        }
    }
}

  .error-message {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: red;
    white-space: pre-wrap;
  } 

  .resend-verification-code-link-container {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: blueviolet;
    white-space: pre-wrap;

    .resend-link {
      text-decoration: underline;
    }
  } 

  .buttons-container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .preload-spinner {
    margin: 160px 0 160px 50%;
    transform: translate(-50%, 0);
    height: 100px;
    width: 100px;
  }
}