@import 'src/app/static/styles/main.scss';


.doctor-register-page-layout {
    
  .progress-bar-placement {
    margin: 0 auto -80px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    padding: 45px 0px 80px;
  }

  .top-paragraph {
    padding-top: 110px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
  }

  .registration-message {
    margin-bottom: 64px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: bold;
    color: rgba(139, 69, 189);
  }

  .selected-address-title {
    margin-bottom: 20px;
    font-size: 2.2rem;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.2;
    color: rgb(65, 118, 187);
  }

  .form-layout {
      max-width: 1000px;

      &.extend {
        display: flex;
        justify-content: center;
        width: 75%;
        padding-bottom: 100px;
        margin-top: 70px;

        .row-layout {
          width: 80%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: baseline;
        
            span {
              font-size: 2.2rem;
              font-weight: bold;
            }
        }
      }

      .clinic-details {
        margin: 40px 0;
        font-size: 2rem;
        letter-spacing: 1px;
        line-height: 1.2;

        .clinic-name {
          font-weight: 800;
        }

        .clinic-address {
          font-weight: 600;
        }

        .clinic-city-state-zip {
          font-weight: 600;
        }
      }

      .clinic-map-container {
        position: relative;
        margin-top: 40px;
        width: 100%;
        height: 400px;
        background-color: rgba(93, 93, 93, 0.092);
        border-radius: 10px;
        overflow: hidden;
        z-index: 2; // Go over the background text

        .clinic-map-background-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .google-map {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }

      .search-contents {
        margin: 40px auto 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .address-search-results {
      max-height: 400px;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 600;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      row-gap: 10px;

      .address-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        column-gap: 10px;
        cursor: pointer;

        .location-name {
          padding: 20px;
          width: 35%;
          min-height: 100%;
          border-radius: 4px;
          background-color: colors(light-grey);
          border-radius: 4px;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .location-address {
          padding: 20px;
          width: 65%;
          min-height: 100%;
          border-radius: 4px;
          background-color: colors(light-grey);
          text-align: left;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .address-text {
            // margin-left: 16px;
            font-size: 80%;
          }
        
          .radio-container {
            margin-right: 20px;
            height: 100%;
            display: flex;
            flex-direction: center;
            align-items: center;

            .fake-radio {
              height: 26px;
              width: 26px;
              border-radius: 50%;
              border: 2px solid #223;
              background-color: #fff;
              color: rgba(101, 177, 193);
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        &.selected {
          .location-name {
            background-color: rgba(0,0,16,.15);
          }
          .location-address {
            background-color: rgba(0,0,16,.15);
          }
        }
      }
    }
  }


  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);

    &.two {
      margin-bottom: 50px;
    }
  }

  .doctor-select-position {
    padding: 50px 0px 50px;
    width: 300px;
  }

  .form-wrapper-top {
      &.p1 {margin-bottom: 50px;}
      &.p2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 25px;
        margin-top: 45px;  //  SPACING HERE
      }
  }

  .form-wrapper-uppermid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    
    .grid-rows {
      display: grid;
      grid-template-columns: 50% 13% 50%;
      justify-content: space-between;
      column-gap: 2.5%;
    }
  }

  .form-wrapper-mid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;

    &.shortened {
      width: 50%;
    }
      &.p1 { padding: 25px 0px 25px }
  }
  
  .form-wrapper-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
  }

  .error-message {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: red;
    white-space: pre-wrap;
  }

  .resend-verification-code-link-container {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: blueviolet;
    white-space: pre-wrap;

    .resend-link {
      text-decoration: underline;
    }
  } 

  .buttons-container {
    margin: 0 auto 120px;
    display: flex;
    justify-content: space-between;
  }

  .preload-spinner {
    margin: 160px 0 160px 50%;
    transform: translate(-50%, 0);
    height: 100px;
    width: 100px;
  }
}