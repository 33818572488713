/** 
 * The styles in this file control how elements are diplayed when the user prints the page.
 *
 * Modifier classes:
 *  - Don't alter elements with these classes: 
 *      .print-only (use the <PrintOnlyContent> component)
 *      .printable-override
 *  - These elements are shown only when printing, but not on website:
 *      .print-only (use the <PrintOnlyContent> component)
 *  - These elements are hidden from print:
 *      .noprint
 *      .clearprint
 *  - These elements are shown in print but get resized:
 *      img
 *  - These elements have their styles removed (usally position), but kept visible:
 *      .print-clear-styles
 *  - Misc
 *      - SVGs not excluded by other rules will be kept.
 */




/** 
 * Add this class to components that should be displayed ONLY when printing.
 *  - Instead of using this class directly, use the <PrintOnlyContent> component.
 */
.print-only {
    display: none !important;
}
@media print {
    .print-only {
        display: unset !important;
    }
}



/** 
 * For hiding elements from print on the fly - add one of these classes to components 
 * that shouldn't be printed.
 *
 * Try to use the below block with global/general styling rules if possible, but these classes
 * are a good option for more fine-tuned display logic.
 */
@media print {
    .noprint {
        display: none !important;
    }
    .clearprint {
        visibility: hidden !important;
    }
}



/** 
 * GLOBAL RULES FOR PRINTING
 *
 * Or enter the classes here with the style of `display: none` or alternate formatting. 
 * Usually you'll need to use `!important` because of selector specificity.
 *
 * Below styles try to use the least and most-general logic possible for printing the
 * text details on a page without all the extras.
 */
@media print {
    *:not(.print-only):not(.printable-override) {
        // These make all elements visible and stacked/ordered cleanly. And kept within 
        // the edges of the page.
        position: static !important;
        flex-wrap: wrap !important;
        // The main goal is just to print the text on the page, without a bunch of extra space.
        // This collapses down unused whitespace.
        height: unset !important;
        width: unset !important;
        min-height: unset !important;
        min-width: unset !important;
        // Some elements have float even though they prob shouldn't.
        float: unset !important;
        // Flex always goes vertically
        flex-direction: column !important;
        // Make sure items are packed closely together and fit on sheet of paper.
        // Add small row-gap/column-gap so there is still clear delineation between elements.
        justify-content: flex-start !important;
        align-items: flex-start !important;
        row-gap: 1px !important; // This breaks print on home page unless <PillButtonLink> hidden.
        column-gap: 1px !important; // This breaks print on home page unless <PillButtonLink> hidden.
        gap: 1px !important; // This breaks print on home page unless <PillButtonLink> hidden.
        // Default webpage has a lot of spacing, so remove it from printing. Add margin/padding of
        // 1px each so there is still clear delineation between elements.
        margin: 1px !important;
        padding: 1px !important;
        // Align all text left - good for display tables.
        text-align: left !important;
        // Big font sizes aren't useful on printed pages. Usually important titles will have
        // higher font-weight's, which are preserved for printing.
        font-size: 12px !important;
        // Background color usually isn't helpful and just uses a lot of ink.
        background: none !important;
        // Allow text to wrap.
        overflow-wrap: wrap !important;
        white-space: normal !important;
        // Keep reasonable line heights
        line-height: 1.2 !important;
        // Etc
        box-shadow: none !important;
    }

    // These elements should have basic display properties like position removed.
    .print-clear-styles:not(.print-only):not(.printable-override) {
        display: block !important;
        position: static !important;
        top: unset !important;
        left: unset !important;
        right: unset !important;
        bottom: unset !important;
        margin-top: unset !important;
        margin-right: unset !important;
        margin-bottom: unset !important;
        margin-left: unset !important;
        padding-top: unset !important;
        padding-right: unset !important;
        padding-bottom: unset !important;
        padding-left: unset !important;
        transform: none !important;
        opacity: 1 !important;
        visibility: visible !important;
    }

    // No need to see the header, navbar, or footer when printing.
    header:not(.print-only):not(.printable-override),
    footer:not(.print-only):not(.printable-override) {
        display: none !important;
    }

    // Hide header banners that aren't within <header> tags
    .header-banner-container-outer:not(.print-only):not(.printable-override) {
        display: none !important;
    }

    // Make all images the same size. Main purpose is to shrink large images.
    img:not(.print-only):not(.printable-override) {
        display: block !important; // This breaks print
        height: 100px !important;
        width: 100px !important;

        // Re-override for `.noprint` selector
        &.noprint {
            display: none !important;
        }

        // Add a line break after all images
        &::after {
            content: "\a" !important;
            white-space: pre !important;
        }
    }

    // Hide button a different way, because using `button { display: none }` breaks print - it
    // causes chrome browser to try to print 16,930 pages.
    button:not(.print-only):not(.printable-override) {
        display: none !important; // This breaks print
        visibility: hidden !important;
        border: none !important;
        outline: none !important;
        height: 0px !important;
        width: 0px !important;
    }
}
