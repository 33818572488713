// ====================================================  CSS Rest


// ============================================
html {
    /* reset font-size to 10px */
    font-size: 0.625rem;

    body {
        height: 100%;
        // Match color of bottom-most footer, which is visible when
        // very zoomed out with tall browser on a short page.
        background-color: colors(dark-blue);
        color: colors(primary-blue);
        font-size: 1.8rem;
        font-family: 'Open Sans', sans-serif;

        a {
            text-decoration: none;

            &:visited {
                color: inherit;
            }
        }
    }

    p {
        line-height: font-sizes(base) * 1.5;
    }
}


// ============================================== Custom overrides

.hidden {
    visibility: hidden;
    overflow: hidden;
    height: 0 !important;
    width: 0 !important;
}

.display-block {
    display: block;
}


.display-none {
    display: none;
}

::placeholder {
    color: revert;
}


// ============================================== Global styling

/** Container for all body objects */
.body-container,
.body-container-no-vert-pad,
.body-container-no-pad {
    margin: 0 auto;
    width: 100vw;
    max-width: $page-max-width;
    padding: $body-top-padding $page-side-padding $body-bottom-padding;
}
.body-container-no-vert-pad {
    padding: 0 $page-side-padding 0;
}
.body-container-no-pad {
    padding: 0;
}

/** For registration steps "preview" links */
.preview-link {
    color: rgb(74,115,179);
    text-decoration: underline;

    &:visited {
        color: rgb(74,115,179);
    }
    &:hover {
        text-decoration: none;
    }
}
.preview-link-active {
    color: rgb(164,185,220);
    text-decoration: underline;

    &:visited {
        color: rgb(164,185,220);
    }
    &:hover {
        text-decoration: none;
    }
}

// ============================================== Plugins styl overrides

// Override styles for toaster plugin.
.Toastify__toast.Toastify__toast {
    background-color: rgba(50, 75, 140, 1);
    color: #fff;
    white-space: pre-line;
    cursor: default;
  }