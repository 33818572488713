@import 'src/app/static/styles/main.scss';

$table-bg: rgba(0, 4, 8, .05);
$table-bg-darker: rgba(0, 4, 8, .09);

.my-account-page {
    margin-bottom: 100px;
    font-family: font-families(base);
    font-size: 1.8rem;
    color: colors(_black);

    .loading-spinner {
        margin: 160px 0 160px 50%;
        transform: translate(-50%, 0);
        height: 100px;
        width: 100px;

        &.for-tag-cycles {
            margin: 8px 0 8px 8px;
            transform: unset;
            height: 50px;
            width: 50px;
        }
    }

    .tag-cycles-message {
        margin: 8px 0 0 8px;
        font-size: 1.4rem;
        font-weight: 600;
        color: #aab;
        white-space: pre-wrap;

        &.red {
            font-size: 1.6rem;
            color: #f34;
        }
    }

    .warning-icon {
        display: inline-block;
        transform: translate(16px, 2px);
        height: 14px;
        width: 14px;
        color: red;
    }

    .error-message {
        margin: 3.2rem auto 0;
        width: 90%;
        max-width: 800px;
        font-size: 1.8rem;
        font-weight: bold;
        color: red;
        text-align: center;
        white-space: pre-wrap;
    }
    
    .section-container {
        display: flex;
        flex-direction: column;
        row-gap: 100px;
        
        .section {
            display: flex;
            flex-direction: column;

            .title-row {
                .section-title {
                    display: inline-block;
                    margin-bottom: 20px;
                    font-size: 2.2rem;
                    font-weight: bold;
                    color: rgb(65, 118, 187);
                }
            }

            button.edit-info {
                display: inline-block;
                margin-left: 12px;
                transform: translateY(-4px);
                padding: 8px;
                width: fit-content;
                border-radius: 6px;
                font-size: 14px;
                font-weight: bold;
                background-color: $table-bg;
                color: rgba(0, 4, 12, .5);

                &:hover {
                    background-color: $table-bg-darker;
                }

                &.main-action {
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }

            table.info-table {
                margin-top: 2rem;
                border-collapse: collapse; 
                table-layout: auto;
                border-spacing: 28px;

                thead tr {
                    border-bottom: 1px solid rgba(0, 4, 12, .3);
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: rgba(0, 4, 12, .8);
                    cursor: default;

                    td {
                        padding: 10px;
                        border-right: 8px solid #fff;
                        background-color: rgba(0, 4, 12, .01);
                        text-align: center;
                    }
                }

                tbody tr {
                    font-size: 1.6rem;

                    &.product-row,
                    &.tag-row {
                        margin-top: 4px;
                        width: 100%;
                        border-bottom: 8px solid #fff;
                        line-height: 2;
                        cursor: pointer;
                    }

                    &.tag-row {
                        cursor: default;
                    }

                    td {
                        overflow-x: scroll;
                    }
                    
                    td.title,
                    td.medium,
                    td.small,
                    td.smaller,
                    td.smallest {
                        padding: 8px 40px 8px 0;
                        width: 20rem;
                        font-weight: bold;
                        transition-duration: 200ms;
                        text-align: left;
                        white-space: nowrap;
                        overflow: scroll;

                        &.product-cell {
                            padding: 8px 16px;
                            width: 30rem;
                            background-color: $table-bg;
                            border-right: 8px solid #fff;
                        }

                        &.untag-cell {
                            padding: 8px;
                            width: 4rem;
                            background-color: $table-bg;
                            border-right: none;
                            text-align: center;
                        }
                    }

                    td.title.title {
                        font-weight: 600;
                        width: 20rem;
                    }

                    td.medium.medium {
                        font-weight: normal;
                        text-align: center;
                        width: 20rem;
                    }

                    td.title.title.medium.medium {
                        font-weight: 600;
                        text-align: left;
                        padding-left: 32px;
                    }

                    td.small.small {
                        font-weight: normal;
                        text-align: center;
                        width: 12rem;
                    }

                    td.smaller.smaller {
                        font-weight: normal;
                        text-align: center;
                        width: 8rem;
                    }

                    td.smallest.smallest {
                        width: 4rem;
                    }

                    td.info {
                        padding: 8px 40px 8px 0;
                        font-weight: 400;
                        transition-duration: 200ms;

                        &.product-cell {
                            padding: 8px 16px;
                            width: 45rem;
                            background-color: $table-bg;

                            &.wide {
                                width: unset;
                            }
                        }
                    }

                    &.product-row:hover,
                    &.tag-row:hover {
                        td.title.product-cell,
                        td.medium.product-cell,
                        td.small.product-cell,
                        td.smaller.product-cell,
                        td.smallest.product-cell,
                        td.info.product-cell,
                        td.untag-cell {
                            background-color: $table-bg-darker;
                        }
                    }
                }

                button.accept-invite-button {
                    padding: 4px 8px;
                    border-radius: 4px;
                    border: 1px solid transparent;
                    color:rgb(65, 118, 187);
                    font-weight: bold;
                    transition-duration: 200ms;
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        background-color: #def;
                        border-color: #abc;
                    }
                }

                button.untag-button {
                    padding: 0px;
                    transform: translateY(4px);

                    svg {
                        height: 20px;
                        width: 20px;
                        color: #f00;
                    }

                    &:hover {
                        svg {
                            color: #b00;
                        }
                    }
                }

                button.edit-tag-button {
                    padding: 0px;
                    transform: translateY(4px);

                    svg {
                        height: 20px;
                        width: 20px;
                        color: #004;
                    }

                    &:hover {
                        svg {
                            color: #469;
                        }
                    }
                }

                button.admin-button {
                    margin-top: -10px;
                    padding: 4px;
                    margin-left: 8px;
                    transform: translateY(4px);
                    border-radius: 8px;

                    svg {
                        height: 20px;
                        width: 20px;
                        color: colors(_blue);
                    }

                    &:hover {
                        background-color: #def;
                        svg {
                            color: #46a;
                        }
                    }
                }

                .info-icon {
                    margin-right: 20px;
                    user-select: none;
                    font-size: 80%;
                    opacity: .5;
                    cursor: pointer;
                }
            }
        }
    }
}
