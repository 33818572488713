@import 'src/app/static/styles/main.scss';



.user-guide-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 120px;
    padding-bottom: 20px;

      .header {
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
          color: black;

          &.blue {
              color: rgb(65, 118, 187);
              padding-bottom: 30px;
          }

          &.small {
              font-size: 2rem;
          
              &.blue {
                color: rgb(65, 118, 187);
              }
          }
    }
  }

  .bold-text {
    font-weight: bold;

    &.spaced {
      padding-top: 15px;
      padding-bottom: 7px;
    }
  }


  .teal-display-container {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-bottom: 30px;
    width: 100%;
    gap: 4%;

    .teal-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 17px;
      width: 48%;
      gap: 15px;
      border-radius: 8px;
      background-color: rgba(89, 190, 204, 255);
      color: white;

      .display-text {
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
  }

  .information-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 3px;
    margin-top: 30px;
    width: 100%;
    gap: 3.3%;

    .header {
      text-align: left;
      font-size: 2.4rem;
      font-weight: bold;
      color: black;
      padding-bottom: 20px;

      &.blue {
          color: rgb(65, 118, 187);
      }
    }


.information-column {
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 20px;

    .information-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 17px;
      width: 100%;
      gap: 8px;
      border-radius: 8px;
      background-color: white;

      .information-text {
        font-weight: bold;      
      }

      span {
        line-height: 1.5;
      }

      .purple-button {
        padding: 4px 10px 4px 10px;
        min-width: 82.5px;
        font-size: 1rem;
        background-color: colors(_purple);
        color: white;
        border-radius: 3px;

        &.sized {
          min-width: 70px;
          width: 70px;
        }
      }

      .text-required-wrap {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        column-gap: 8px;

        &.purple {
          column-gap: 4px;
        }
      }

      .span-padding {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .white-space {
        padding-bottom: 10px;
        color: white;
       }
    }
  }
  }

}