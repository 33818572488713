@import 'src/app/static/styles/main.scss';

.input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    
    .input {
        box-sizing: border-box;
        margin-bottom: 4px;
        padding: 12px 20px;
        width: 100%;
        border: none;
        border-radius: 6px;
        background-color: colors(light-grey);
        color: colors(_black);
        font-family: font-families(base);
        font-size: font-sizes(input);
        font-weight: 600;
        line-height: font-sizes(input) * 1.5;

        &.outlined {
            background-color: white;
            border-radius: 4px;
            border: 2px solid black;
            padding: 8px 16px;
        }

        &:focus {
            outline: none;
        }

        /* Don't show arrow buttons input [type=number] */
        // Chrome, Safari, Edge, Opera
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        // Firefox
        &[type=number] {
            -moz-appearance: textfield;
        }

        // Different styling if input field is disabled
        &:disabled {
            color: rgba(0,0,8,.6);
            font-weight: 400;
            user-select: none;
        }
    }

    /*
    * ==================  Alt styles
    */

    &.light-border {
        input {
            background: transparent;
            border: $light-border;
        }
    }

    &.medium-border {
        input {
            background: transparent;
            border: 2px solid rgba(0, 0, 0, .1);
        }
    }

    &.credit-app {
        width: 100%; 

        &.city {
            width: 60%;
        }
    }

    .success-message {
        margin-top: 12px;
        min-height: 4rem;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: bold;
        color: #394;
        white-space: pre-wrap;
    }

    .error-message {
        margin-top: 12px;
        min-height: 4rem;
        font-size: 1.6rem;
        line-height: 1.2;
        font-weight: bold;
        color: red;
        white-space: pre-wrap;
    }

    /*
     * Small variant
     */
    &.small {
        input {
            margin-bottom: 0;
            padding: 0px 12px;
            font-size: 16px;
            color: rgba(0,0,8,.8);
        }

        .success-message {
            font-size: 1.4rem;
            line-height: 1.1;
        }

        .error-message {
            font-size: 1.4rem;
            line-height: 1.1;
        }
    }

    // Alternate display, used on registration forms (and probably anywhere that needs to show
    // error messages or success messages underneath the input).
    &.alt {
        .error-message,
        .success-message {
            margin: 2px 0 -16px 0;
        }
    }
}