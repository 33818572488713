@import 'src/app/static/styles/main.scss';

.generic-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: 0.2;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover,
    *:focus {
      opacity: 0.3;
    }
  }

  .modal-contents {
    height: 100%;
    width: 100%;
  }

  .bg-shade {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    z-index: 2;
    background: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    border-radius: 5px;

    &.icd-list {
      position: fixed;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 50px;
      border-radius: 5px;
      border-top-left-radius: 415px;
      color: rgb(56, 49, 49);
      background: #457ebe;
      background: linear-gradient(
        15deg,
        #03030350 5%,
        #91a1b385 25%,
        #457dbe85 85%,
        #5abdcc8f 90%
      );
    }

    .title {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2.8rem;
      font-weight: 600;
    }

    .subtitle {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
      font-style: italic;
      white-space: pre-line;
    }

    .location {
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
      white-space: pre-line;
    }
  }
}
