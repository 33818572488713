@import 'src/app/static/styles/main.scss';


.product-detail-page-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

.header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: black;
    
    &.blue {
        color: rgb(65, 118, 187);
        padding-bottom: 30px;
    }
    
    &.small {
        font-size: 2rem;

        &.blue {
          color: rgb(65, 118, 187);
        }
    }
}

.formwrapper-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 60px;

    .category-1-wrapper {
        display: flex;
    }
    
    .category-2-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        background-color: white;
    }
}

.selection-container {
    margin: 0 0 16px;
    width: 100%;

    .selection-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        row-gap: 60px;
        column-gap: 60px;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 60px;

        .selection-section {

            &.dose-section {
              max-width: 400px;
            }

            .selection-title {
                margin-bottom: 24px;
                width: 100%;
                font-size: 2.2rem;
                font-weight: bold;
                text-transform: uppercase;
                color: rgb(45, 50, 75)
            }

            .selection-subtitle {
                // Use -/+ top/bottom margins bc this div will only sometimes exist.
                margin: -18px 0 8px 0;
                width: 100%;
                font-size: 1.2rem;
                font-weight: bold;
                color: rgba(45, 50, 75, .6);
            }

            input.quantity-input {
                padding: 8px 8px 8px 16px;
                width: 80px;
                border: 2px solid #e3e0e0;
                border-radius: 4px;
                background-color: white;
                color: colors(_black);
                font-family: font-families(base);
                font-size: 1.8rem;
                font-weight: 600;
                // line-height: font-sizes(input) * 1.5;

                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.formwrapper-mid {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 60px;
    width: 100%;
    background-color: rgb(243, 243, 243);

    .category-3-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 40px;
        padding-bottom: 80px;
        width: 100%;
    }
}

.formwrapper-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  
.category-4-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding-bottom: 80px;
  background-color: white;
}
}

.product-display-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 45px;
}

.multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-bottom: 50px;
}

.pdf-web-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 120px;
  padding-bottom: 50px;


  .icons {
    width: 25px;
    margin: 0px 30px -5px 0px;
  
    &.web-icon {
        
    }
  
    &.pdf-icon {
      
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  li {
    font-weight: bold;
    padding: 25px 15px 0px 0px;
    width: 100%;
  }
}

}