@import 'src/app/static/styles/main.scss';



.registered-procedures-layout {
  
  .header {
    padding-top: 200px;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
    
    &.small {
        font-size: 2rem;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
}