@import 'src/app/static/styles/main.scss';

$nav-color-start: #4176bb;
$nav-color-end: #4b99c4;
$logo-scale: .5;
$logo-aspect-ratio: 1103/256;
$logo-graphic-aspect-ratio: 210/256;
$min-space-between: 20px;

header {
  position: fixed;
  top: 0;
  height: $header-height;
  width: 100%;
  background-color: #fff;
  z-index: 5;

  &.is-admin {
    height: 50px;
    background-color: #000;
  }


 .close-position {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: .2;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:hover,
    *:focus {
      opacity: .3;
    }
}

  .modal-contents {

      .button-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
      }
    }

  .header-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: $min-space-between;

    &.is-admin {
        margin: 0px auto;
        width: calc(100vw - 100px);
    }
    
    .center-items {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.logo-container {
        padding-top: 4px;
        flex-shrink: 0; 
        cursor: pointer;

        .logo-full {
          display: block;
          &.is-admin { display: none }
        }

        .logo-graphic {
          display: none;
          &.is-admin { display: block }
        }

        img {
          height: $header-height * $logo-scale;
          width: ($header-height * $logo-scale) * $logo-aspect-ratio;

          &.is-admin {
            height: ($header-height * $logo-scale) / 2;
            width: unset;
          }
        }
      }

      &.search-container {
        margin-left: $min-space-between;
        padding: $search-container-padding-vertical $search-container-padding-horizontal;
        height: $search-container-height;
        flex-grow: 1;
        border-radius: 4px;
        background-color: colors(light-grey);
      }


    //  CART AND PROFILE STYLES.

    
    &.icon-buttons {
      margin-left: -10px;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .icon-button {
        position: relative;
        margin-left: 24px;
        border: none;
        background-color: transparent;
        color: colors(primary-blue);
        display: flex;
        justify-content: center;
        align-items: center;

        &.is-admin {
          color: #def;
        }


        svg {
          width: $header-icon-size;
          height: $header-icon-size;
        }

        .cart-size {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -8px;
          right: -8px;
          aspect-ratio: 1 / 1;
          min-height: 20px;
          border-radius: 50%;
          background-color: rgb(169, 34, 136);
          color: #fff;
          font-weight: bold;
          font-size: 12px;
          
        }
      }
    }

    &.icon-buttons-notloggedin {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;
      column-gap: 10px;
      margin-left: 10px;
      
      .block-button {
        padding: 0 32px;
        font-size: 1.6rem;
      }
    }



    }
  }
}

// Spacer that is equal to header + navbar height.
.header-spacer {
  width: 100%;
  height: $header-height;
}

// Give header icons different styling when hovered or active.
.dynamic-icon {
  &:hover {
    opacity: .8;
  }
  &:active {
    opacity: 1;
  }
}


/** 
 * Responsive styling
 */
@include screen-below-mobile-820 {
  header {
    height: $header-height * .8;

    .header-wrapper {
      column-gap: 0;
      
      .center-items {
        &.logo-container {
          margin: 0;

          .logo-full { 
            display: none;
          }

          .logo-graphic {
            display: block;
          }

          img {
            height: ($header-height * $logo-scale) * .8;
            width: ($header-height * $logo-scale) * $logo-graphic-aspect-ratio * .8;
          }
        }

        &.search-container {
          margin-left: $min-space-between;
          padding: ($search-container-padding-vertical / 2) ($search-container-padding-horizontal / 2);
          height: $search-container-height * .8;

          .search-dropdown {
            .active-category {
              margin-left: -$search-container-padding-horizontal / 2;
              padding-left: $search-container-padding-horizontal * .75;
              padding-right: ($search-container-padding-horizontal / 2) + ($dropdown-icon-size * 1.5);
              height: $search-container-height * .8;
              font-size: 1.5rem;

              svg {
                height: $dropdown-icon-size * .6;
                width: $dropdown-icon-size * .6;
              }
            }
          }
        }
        
        &.icon-buttons {
          margin-left: 0px;

          .icon-button {
            margin-left: 20px;

            svg {
              width: $header-icon-size * .6;
              height: $header-icon-size * .6;
            }
          }
        }

        &.icon-buttons-notloggedin {
          margin-left: $min-space-between;

          .block-button {
            padding: 0 16px;
            height: 36px;
            font-size: 1.2rem;
            font-weight: bold;
            flex-shrink: 2;
          }
        }
      }
    }
  }

  .header-spacer {
    width: 100%;
    height: $header-height * .8;
  }

}
