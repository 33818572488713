.div-contain-checkbox {
  width: 80px;
  height: 0px;

  .checkbox-label {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.5rem;
    color: black;
    line-height: 1.7;
    display: grid;
    grid-template-columns: 15px auto;
    gap: 27px;
  }

  input[type='checkbox'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */

    margin: 0;
    background-color: white;
    width: 25px;
    height: 25px;
    border: 2px solid black;
    border-radius: 3px;
    transform: translateY(-1.78px);
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type='checkbox']::before {
    content: '';
    width: 1.6em;
    height: 1.6em;
    clip-path: polygon(39% 62%, 82% 27%, 90% 36%, 39% 76%, 10% 53%, 19% 44%);
    transform: scale(0);
    transform-origin: bottom left;
    box-shadow: inset 26.4px 26.4px white;
    /* Windows High Contrast Mode */
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }

  input[type='checkbox']:checked {
    background-color: rgba(89, 190, 204, 255);
    border-color: rgba(89, 190, 204, 255);
  }
}
