@import 'src/app/static/styles/main.scss';


.baa-page {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    .main-container {
        // margin: 80px;
        // max-width: 1000px;
    }

    .header {
        text-align: center;
        font-size: 5rem;
        padding-top: 10px;

        &.sections {
            font-size: 2.5rem;
            font-weight: bold;
        }

        &.bottom {
            padding: 80px;
            font-size: 3rem;
        }
    }

    .wrapper {
        padding-top: 200px;
    }
    
    h2 {
        font-size: 2rem;
        font-weight: bold;
    }

    li {
        padding-left: 30px;
    }
    

    .function-area {
        padding-top: 200px;
        padding-bottom: 300px;
        text-align: center;
    }
}