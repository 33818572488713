@import 'src/app/static/styles/main.scss';

.image-container {
    width: 100%;

  .banner-span {
      background-image: url("./doctor-profile-banner.png");
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 730px;

      .card {
        display: flex;
        width: 95%;
        height: 440px;
        margin-top: 155px;
        border-radius: 7px;
        background-color: white;
        box-shadow: 0px 15px 20px 10px rgba(10, 10, 10, 0.195);

        .card-content {
          display: flex;
          flex-direction: row;
          gap: 40px;
          text-align: left;

          .profile-image {
            height: 100%;
            width: 420px;
            border-bottom-left-radius: 7px;
            border-top-left-radius: 7px;
          }

          .card-list {
            display: flex;
            flex-direction: column;
            row-gap: 25px;
            padding: 50px 90px 50px 20px;

          .header {
            font-size: 3rem;
            font-weight: bold;
            color: rgb(65, 118, 187);
        }

        .career {
          font-size: 2rem;
          font-weight: bold;
        }

        .stars {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .star1 {
            display: flex;
            background-color: rgba(202, 202, 202, 0.658);
            width: 20px;
            height: 20px;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);

            &:hover {
              background-color: colors(_purple);
              cursor: pointer;
            }
        }
      }

          .button-group {
            display: flex;
            gap: 20px;
          }

          .description {
            margin-top: 50px;
            line-height: 25px;
          }

        }
      }

      }
    }
  }