@import 'src/app/static/styles/main.scss';

.image-container {
    width: 100%;

  .skel-span {
      background-image: url("./public-home-skel.png");
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: flex-start;
      width: 100%;
      padding: 60px 0px;
  
      .content-platform {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          width: 100%;
          padding: 0 $page-side-padding;
          text-align: left;
          gap: 8px;
          color: white;

          .content-platform-inner {
              max-width: 660px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              row-gap: 24px;
              text-align: center;
              
              .small-title {
                  font-size: 3rem;
                  letter-spacing: 2px;
              }

              .big-title {
                  font-size: 5rem;
                  line-height: 8rem;
                  font-weight: bold;
                  letter-spacing: 3px;
              }

              .pill-button {
                  margin-top: 8px;
                  width: fit-content;
              }
          }
      }
  }
}