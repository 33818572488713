@import 'src/app/static/styles/main.scss';


.img-input-wrapper {
    position: relative;
    border-radius: 10px;
    background-color: rgba(93, 93, 93, 0.092);
    border: 1px solid rgba(93, 93, 93, 0.092); // to be visible with white-background images.
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .upper {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 0; // Beaneath everything

        .background-image {
            height: 80px;
            width: 110px;
        }
    }

    .lower {
        padding-top: 20px;
        height: 50%;
        font-size: 1.5rem;
        font-weight: bold;
        color: black;
        text-align: center;
        letter-spacing: 0.7px;
        z-index: 0; // Beneath the image

        .title {
            text-decoration: underline;
        }

        .desc {
            margin-top: 8px;
        }
    }

    .real-image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1; // Over .upper and .lower

        .s3image-img {
            display: block;
            // (width and height and position should be set in react component)
        }
    }

    .img-input {
        display: none; // Hidden input triggered by JS
    }

    .input-button { 
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 2; // Over everything
    }

    .img-loading-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 100px;
        width: 100px;
        opacity: 50%;
        z-index: 1; // Over everything except for the clickable transparent button.
    }

    .bottom-note-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 12px 24px;
        width: 100%;
        height: fit-content;
        background-color: rgba(0,0,0,.5);
        color: white;
        font-size: 1.5rem;
        line-height: 1.6;
        text-align: center;
        font-weight: bold;
        opacity: 1;
        z-index: 1; // Over everything except for the clickable transparent button.
        // Transition to hide the grey box.
        transition-duration: 400ms;
        transition-delay: 400ms;
        transition-property: bottom, opacity;
    }

    &:hover {
        .bottom-note-overlay {
            bottom: -20px;
            opacity: 0;
        }
    }
}
