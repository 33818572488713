

.order-summary-table {
    .order-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 5px 10px;
        
        thead tr th {
            padding: 0 3px 10px;
            text-align: center;
            text-transform: uppercase;
            
            color: #fff;

            span {
                display: flex;
                justify-content: center;
                background: colors(primary-blue);
                padding: 10px;
                width: 100%;
            }

            &:first-of-type {
                text-align: left;
                padding-left: 0;

                span {
                    display: inline-block;
                }
            }
            &:last-of-type {
                padding-right: 0;
            }
        }

        tbody tr td {
            padding: 0 3px 6px;
            text-align: center;
            overflow: hidden;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                background: rgba(0,0,0,0.1);
                padding: 10px;
                width: 100%;
            }

            &:first-of-type {
                text-align: left;
                padding-left: 0;

                span {
                    display: inline-block;
                }
            }
            &:last-of-type {
                padding-right: 0;
            }
        }
    }
}