@import 'src/app/static/styles/main.scss';



.study-dashboard-layout {

  .header {
    padding-top: 210px;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
  }

  .directions-area {
    margin-top: 40px;
    font-size: 1.7rem;
  }

  .form-area {
    display: flex;
    margin-top: 80px;


    .uploaded-forms {
      display: flex;
      flex-direction: column;
      row-gap: 23px;
      width: 23%;
    }

    .form1 {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 1.7rem;
          color: black;
          line-height: 1.5;
          display: grid;
          grid-template-columns: 15px auto;
          gap: 43px;
          
          &.current {
            color: rgba(89, 190, 204, 255);
            font-size: 2.2rem;
            font-weight: bold;
          }

        .circlecheck {
          /* Add if not using autoprefixer */
          -webkit-appearance: none;
          /* Remove most all native input styles */
          appearance: none;
          /* For iOS < 15 */
      
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          background-color: rgba(89, 190, 204, 255);
          width: 26px;
          height: 26px;
          border-radius: 50%;
          display: grid;
          place-content: center;
          cursor: pointer;

          &.null {
            background-color: rgba(93, 93, 93, 0.092);

            &.current {
              width: 35px;
              height: 35px;
              margin-left: -4px;
            }
          }

          .checkmark {
            width: 20px;
            height: 20px;
            clip-path: polygon(39% 62%, 82% 27%, 90% 36%, 39% 76%, 10% 53%, 19% 44%);
            box-shadow: inset 26.4px 26.4px white;
            /* Windows High Contrast Mode */
          }
        }
    }

    .grey-divider {
      width: 92%;
      height: 4px;
      margin-left: -15px;
      border-radius: 10px;
      background-color: rgba(93, 93, 93, 0.092);
    }

    .form-vis {
      width: 77%;
    }
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }
}