@import 'src/app/static/styles/main.scss';


.greyback {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 10px;
    letter-spacing: 0.7px;
    padding: 50px 75px 50px 55px;
    width: 100%;
    background-color: rgba(93, 93, 93, 0.092);
    color: black;
    overflow: hidden;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 400ms;

    &.base-content {
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding: 0px;
    }

    &.hide {
        opacity: 0;
    }
  
    .x-button {
        display: flex;
        position: absolute;
        padding: 1px 0 0 1px;
        top: 10px;
        right: 10px;
        justify-content: center;
        align-items: center;
        width: font-sizes(button);
        height: font-sizes(button);
        border: 3px solid rgba(0, 0, 16, 1);
        border-radius: 50%;
        color: rgba(0, 0, 16, 1);
        font-weight: 600;
        font-size: 1.4rem;
        opacity: .3;
        transition-property: opacity;
        transition-duration: 200ms;

        &:hover {
            opacity: .55;
        }
    }



    .space-maintain {
        width: font-sizes(button);
        height: font-sizes(button);
        margin-top: -40px;
    }
}