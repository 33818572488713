@import 'src/app/static/styles/main.scss';

$initial-width: 410px;
$initial-height: 430px;
$final-width: 250px;
$final-height: 215px;

.temp-redirect-modal {
  .redirecting-message-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 200;
    background-color: rgba(0,0,0,.6);
    color: colors(dark-blue);
    opacity: 0;

    &.full-opacity {
      opacity: 1;
      transition: background-color 400ms ease-out;
      background-color: rgba(0,0,0,.85);
    }

    .redirecting-message {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: $initial-width;
      height: $initial-height;
      max-width: 500px;
      padding: 25px;
      border-radius: 8px;
      background-color: #fff;
      text-align: center;
      font-size: 28px; // intentional px instead of rem
      line-height: 5rem;
      font-weight: 600;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.full-opacity {
        width: $final-width;
        height: $final-height;
        transition: all 400ms ease-out;
      }  

      img {
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 36px;
        width: 50px;
      }
    }
  }

  .modal-main {
    width: 40%;
    min-width: 440px;
    max-width: 600px;

    .modal-contents {
      .form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
      .button-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
      }
    }

    .error-message {
      margin-top: 1.6rem;
      font-weight: bold;
      font-size: 1.6rem;
      text-align: center;
      color: red;
      white-space: pre-wrap;
    }

    .success-message {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      color: green;
    }
  }
}