@import 'src/app/static/styles/main.scss';


.doctors-layout {
  background-color: rgba(93, 93, 93, 0.092);
  width: 100%;
  padding-bottom: 300px;

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding-top: 120px;
    padding-bottom: 40px;

      .header {
          text-align: left;
          font-size: 3rem;
          font-weight: bold;
          color: black;

          &.blue {
              color: rgb(65, 118, 187);
          }

          &.small {
              font-size: 2rem;
          
              &.blue {
                color: rgb(65, 118, 187);
              }
          }
      }
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 3px;
    width: 100%;

    .top-row {
      display: flex;
      flex-direction: row;
      gap: 1%;
      width: 100%;

      .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: white;
      padding: 20px 15px 20px;
      width: 24.3%;

      .header {
        text-align: left;
        font-size: 1.5rem;
        font-weight: 700;
        color: black;
  
        &.blue {
          color: rgb(65, 118, 187);
          font-size: 2rem;
        }
      }
      }
    }

    .search-container {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      width: 100%;
      background-color: white;
      margin-top: 20px;

      .columns-mid {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px 15px 20px;
        width: 100%;
      
      &.text-box {
        width: 20%;
      }

      &.dropdown-box {
        width: 25%;
        margin-left: 60px;
      }

      &.search-box {
        width: 49.4%
      }
    }
    }

    .text-span {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;
      gap: 12px;

        .text-one {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          text-align: center;
          gap: 7px;
        }

      .circle-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-weight: bold;
        background-color: black;
        color: white;
        width: 25px;
        height: 25px;
      }
    }

    .bottom-container {
      display: flex;
      flex-direction: row;
      gap: 1%;
      width: 100%;

      .columns-bottom {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: white;
        padding: 20px 15px 20px;
        width: 24.3%;

        .header {
          text-align: left;
          font-size: 1.5rem;
          font-weight: 700;
          color: black;
    
          &.blue {
            color: rgb(65, 118, 187);
            font-size: 1.7rem;
          }

          &.red {
            color: red;
            font-weight: bold;
          }
        }

        &.add-doctor {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 18%;
            cursor: pointer
          }

          .icon-text {
            font-weight: bold;
          }
        }

        &.doctor-info-card {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .info-span {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;

            .circle-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              font-weight: bold;
              background-color: black;
              color: white;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }

            img { 
              width: 20px;
              cursor: pointer;
            }
          }
        }

        .teal-container {
          display: flex;
          flex-direction: column;
          padding: 10px 0px;
          background-color: rgba(89, 190, 204, 255);
          color: white;
          font-size: 1.5rem;
          gap: 5px;

          .tealtext {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            text-align: center;
            gap: 10px;

            .days-box {
              width: 30px;
              border-radius: 4px;
              background-color: white;
              color: red;
              font-weight: bold;
            }
          }
        }
      }
    }

    .end-results {
      width: 100%;
      background-color: white;
      text-align: center;
      padding: 25px;
      margin-top: 30px;
      font-weight: bold;
    }
  }
}