@import 'src/app/static/styles/main.scss';

.doctorpage-layout {


.formwrapper-top {
  display: flex;
  margin: 0 auto;
  
  .about-practice-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5%;
  
    .about-practice {
        margin-top: 50px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        width: 45%;
  
        .about-practice-text {
            line-height: 28px;
        }
    }

    .greyback-container {
      margin-top: 45px;
      width: 60%;
      height: 100%;
    }
  }
}

  .multicolor-headers {
    display: flex;
    flex-direction: row;
    text-align: center;
    gap: 12px;
    padding-bottom: 55px;
  }

  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: black;

    &.blue {
        color: rgb(65, 118, 187);
    }
    &.small {
      padding-bottom: 25px;
      font-size: 2.8rem;
  }

  &.small.blue {
      color: rgb(65, 118, 187);
  }
}
.formwrapper-mid {
  background-color: rgb(243, 243, 243);
  width: 100%;

.about-physician {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;

  .inner {
      margin-top: 50px;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;

      .about-physician-text {
          line-height: 28px;
      }
  }


.cards {
  $column-gap: 60px;

  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: $column-gap;
  row-gap: 60px;
  width: 88%;

  .card {
      border-radius: 10px;
      width: calc(50% - ($column-gap /2));
      padding: 40px;
      background-color: white;
      box-shadow: 10px 10px 20px 3px rgba(117, 117, 117, 0.129);

      .dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          margin: 4px 23px 0px 0px;
          border-radius: 50%;
          background-color: colors(_purple);
      }

      ul {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
      }
    
      li {
          display: flex;
          flex-direction: row;
          margin-top: 24px;
          padding-right: 16px;
          height: 100%;
          font-weight: 600;
          font-size: 1em;
          line-height: 120%;
          // padding: 25px 15px 0px 0px;
          width: 100%;

          .li-text {
              width: calc(100% - 10px);

              a {
                  color: colors(_blue);
                  text-decoration: underline;
                  font-weight: bold;
              }
          }
      }
  }

  .certifications {
      height: 300px;
      width: 650px;
      border-radius: 10px;
      background-color: white;
  }
}

}
}

.formwrapper-bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 10%;

  .doctor-contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
    margin-top: 90px;
    padding-bottom: 180px;
    gap: 20px;

    .category-1-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      gap: 15px
    }

    .category-2-wrapper {
      display: flex;
      flex-direction: row;
      gap: 15px
    }

    .category-3-wrapper {
      gap: 35px;
    }
  }

  .privacy-container {
    display: flex;
    flex-direction: column;
    width: 35%;
    justify-content: flex-end;
    margin-bottom: 200px;
    gap: 30px;
    

    .privacy-image {
      width: 60px;
    }

    .header-small {
      font-size: 2rem;
      font-weight: bold;
    }

    .privacy-text {
      line-height: 23px;
    }
  }
}
}