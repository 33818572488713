@import 'src/app/static/styles/main.scss';

.price-toggle {
  font-family: 'Open Sans';
  font-weight: bold;
  padding: 6.7px;
  padding-left: 41px;
  padding-right: 41px;
  font-size: 3.5rem;
  text-transform: uppercase;
  color: white;
  background-color: #070d27;
  border: 1.5px solid #070d27;
  border-radius: 2.4px;
  outline: none;
  cursor: pointer;

  &.darktoggletext {
    margin-top: 3px;
    background: transparent;
    font-size: 1.25rem;
    color: white;
    margin-bottom: -2px;
    padding: 0px;
  }

  &.white1 {
    background-color: white;
    color: #303a57;

    &.whitetoggletext {
      margin-top: 3px;
      margin-left: 8px;
      color: #303a57;
      background-color: transparent;
      background: transparent;
      font-size: 1.25rem;
      margin-bottom: -2px;
      padding: 0;
      border: 0;
    }
  }
}

.mltoggle {
  font-family: 'Open Sans';
  font-size: 1.5rem;
  font-weight: 600;
  padding: 8px;
  padding-left: 19px;
  padding-right: 19px;
  border: 0px;
  border-radius: 1px;
  color: white;
  background-color: #070d27;
  cursor: pointer;

  &.ml-white1 {
    background-color: white;
    color: #303a57;
    border: 0.7px solid #070d27;
  }
}
