@import 'src/app/static/styles/main.scss';



.vendor-dashboard-layout {
  padding-bottom: 140px;

  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;

    &.products {
      font-size: 1.8rem;
      padding-bottom: 20px;
    }
  }
  
  .header-section {
    padding-top: $body-top-padding;
    width: 100%;

    .page-title {
      width: 100%;
      text-align: left;
      font-weight: bold;
      font-size: 2.1rem;
    }
    
    .name-and-datefilter-container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .page-subtitle {
        width: 50%;
        font-weight: bold;
        font-size: 2.6rem;
        color: rgb(65, 118, 187);
      }

      .datepicker-wrapper {
        padding: 16px 32px;
        border: 2px solid black;
        border-radius: 5px;
        font-weight: bold;
      }
    }
  }

  .salesnumbers-container {
    margin-top: 30px;
    width: 100%;
    background-color: rgba(224, 224, 224, 0.58);

    .sections {
      padding: 90px 0px 60px 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 50px;
        width: 30%;

        &.chart {
          width: 30%;
          row-gap: 30px;
      //    margin-bottom: 50px;
        }
      }

      .data {
        font-size: 3.5rem;
        font-weight: bold;
        letter-spacing: 3px;
        text-align: center;
      }

      .color-row {
        display: flex;
        flex-direction: row;
        align-items: baseline;
      //  margin-top: -50px;
        gap: 15px;
        
      
      .vertical-color {
        width: 25px;
        background-color: colors(_purple);

        &.one {
          height: 60px;
        }

        &.two {
          height: 70px;
        }

        &.three {
          height: 50px;
        }

        &.four {
          height: 35px;
        }
      }
    }

      .title {
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;
        letter-spacing: 1px;

        &.chart {
      //    margin-top: -40px;
        }
      }
    }
  }

  .recent-orders-wrapper {
    width: 100%;
    margin-top: 80px;
  }

  .heatmap-wrapper {
    margin-top: 100px;
  }

  .products-wrapper {
    margin-top: 40px;
    padding-bottom: 120px;

    .products-grid {
      margin-top: 58px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 20px;
      column-gap: 70px;
    }
  
    .grey-boxes {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 50px;
      background-color: rgba(224, 224, 224, 0.581);
    }
  }

  .image1 {
    margin-top: 70px;
    margin-left: 70px;
    height: 400px;
    width: 900px;
  }

  .events-wrapper {
    align-items: center;
    margin-top: 80px;

    .events-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

      .event {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($color: #000, $alpha: 0.1);

        .bg {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: -1;
        }

        &.main-event {
          aspect-ratio: 1/1;
        }

        p {
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-weight: font-weights(semi-bold);
        }
      }

      .sub-events {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 10px;
      }
    }
  }
}