@import 'src/app/static/styles/main.scss';

// CONFIGURABLE VALUES
$card-height: 450px;
$card-width: 100%;
$vertical-margin: 24px;
$side-card-protrusion: 50px;
$move-in-amount: 10px;
$unfocused-opacity: .85;
$side-margin-size: calc($page-side-padding + $side-card-protrusion);
// This number needs to match `ANIMATION_DURATION` in the .tsx file.
$animation-duration: 500ms;


.carousel-container {
    position: relative;
    height: $card-height + ($vertical-margin * 2);
    width: calc(100vw - ($side-margin-size * 2));
    max-width: calc(1000px - ($side-margin-size * 2));

    .carousel-container-inner {
        margin-left: calc(50% - ($card-width / 2));
        height: 500px;


        .card {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: $vertical-margin;
            height: $card-height;
            width: calc($card-width);
            border: 1px solid #ddd;
            transition: transform $animation-duration ease, opacity $animation-duration ease-in;
            border-radius: 20px;
            box-shadow: 0px 15px 20px 3px rgb(117 117 117 / 20%);
            z-index: 1;
            overflow: hidden;

            // The card under the "left" card"
            &.left2 {
                transform-origin: center left;
                transform: translateX(-$side-card-protrusion + $move-in-amount) scale(.9) rotate3d(0,1,0, -22deg);
                opacity: 0;
            }
            
            // The left card, under the main card.
            &.left {
                transform-origin: center left;
                transform: translateX(-$side-card-protrusion) scale(.9) rotate3d(0,1,0, -22deg);
                opacity: $unfocused-opacity;
            }

            // The main/center card.
            &.mid {
                transform-origin: center right;
                transform: translateX(0) scale(1);
                opacity: 1;
                z-index: 2;
            }

            // The right card, under the main card.
            &.right {
                transform-origin: center right;
                transform: translateX($side-card-protrusion) scale(.9) rotate3d(0,1,0, 22deg);
                transition: transform 100ms ease, opacity 100ms ease-in;
                opacity: $unfocused-opacity;
            }

            // The card under the "right" card.
            &.right2 {
                transform-origin: center right;
                transform: translateX($side-card-protrusion - 0) scale(.9) rotate3d(0,1,0, 22deg);
                opacity: 0.05;
            }
        }
    }
}