@import 'src/app/static/styles/main.scss';


.consumer-register-page-layout {

  .progress-bar-placement {
    margin: 0 auto -80px;
    max-width: 50%;
    display: flex;
    justify-content: center;
    padding: 45px 0px 80px;
  }

  .top-paragraph {
    padding-top: 110px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
    line-height: 1.5; 
  }

  .registration-message {
    margin-bottom: 64px;
    text-align: center;
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: bold;
    color: rgba(139, 69, 189);
  }

  .form-layout {
    max-width: 1000px;
  }

  .header {
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
  }

  .header3 {
    text-align: center;
    margin: 100px 0px 80px;
    font-size: 3rem;
    font-weight: bold;
    color: rgb(65, 118, 187);
  }

  .center-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    padding-bottom: 150px;

    span {
      font-weight: bold;
      font-size: 3rem;
      letter-spacing: 2px;
    }
  }

  .field-group-wrapper {
    padding: 50px 0px 50px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    column-gap: 25px;

    .field-row {
      margin-top: 0px;
      max-height: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 25px;
      transition-duration: 500ms;
      overflow: hidden;

      &.hide-slow {
        max-height: 0;
        margin-top: -25px;
      }
    }
  }

  .error-message {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: red;
    white-space: pre-wrap;
  } 

  .resend-verification-code-link-container {
    margin-top: 3.2rem;
    margin-bottom: -25px;
    font-size: 1.6rem;
    font-weight: bold;
    color: blueviolet;
    white-space: pre-wrap;

    .resend-link {
      text-decoration: underline;
    }
  } 

  .buttons-container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  .preload-spinner {
    margin: 160px 0 160px 50%;
    transform: translate(-50%, 0);
    height: 100px;
    width: 100px;
  }

  .radio-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    transition-duration: 500;
    
    &.hide-slow {
      max-height: 0;
      margin-top: -25px;
    }

    .options {
      display: flex;
      align-items: center;
      row-gap: 8px;
      flex-wrap: wrap;

        input[type="radio"] {
          display: none;
        }
        label {
          margin-right: 10px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-weight: 600;
          opacity: .8;
          color: #556;
          cursor: pointer;
        }
        input[type="radio"]:checked + label {
          background-color: #ccc;
          color: #249;
          font-weight: 700;
          border: 1px solid rgba(32, 64, 145, .5);
      }
    }

    .radio-title {
      margin-left: 24px;
      font-size: 1.8rem;
      color: rgba(0, 4, 12, .65);
      font-weight: 600;
    }
  }

  .directions-text {
    font-size: 1.4rem;
    line-height: 1.3;
    color: rgba(0, 4, 12, .65);
    overflow: hidden;
    transition-duration: 200ms;
  }

  .search-result-rows {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition-duration: 800ms;

    .search-result-row-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-result-row {
        padding: 20px 20px;
        width: calc(100% - 40px);
        background-color: rgba(222, 238, 255, .5);
        border-bottom: 1px solid #eee;
        border-radius: 6px;
        font-size: 1.8rem;
        color: rgba(0, 4, 12, .65);
        font-weight: 600;
        overflow: hidden;
        cursor: pointer;
        transition-duration: 200ms;
        display: flex;
        justify-content: space-between;
        align-items: center;;

        &:hover {
          background-color: rgba(222, 238, 255, .8);
        }

        &.collpase {
          height: 0;
          padding-top: 0;
          padding-bottom: 0;
        }

        &.highlight {
          font-weight: bold;
          background-color: rgba(222, 238, 255, 1);
          border: 1px solid #abf;
        }
      }
    }
  }

  .x-button {
    padding: 1px 0 0 1px;
    margin-right: 10px;
    width: font-sizes(button);
    height: font-sizes(button);
    border-radius: 50%;
    color: rgba(0, 0, 16, 1);
    font-weight: 600;
    font-size: 1.4rem;
    opacity: .3;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: opacity;
    transition-duration: 200ms;

    &:hover {
        opacity: .55;
        background-color: rgba(0, 4, 12, .1);
    }
  }
}